<template>
  <div>
    <lottie :options="defaultOptions" style='height:fit-content; width:fit-content' />
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import animationData from "@/assets/lottie/car-loading.json";
export default {
  name: "TheMainLoader",
  components: {
    lottie: Lottie
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1
    };
  }
};
</script>

<style scoped lang="scss">

.darkBG {
  background: rgba(0, 40, 155, 0.5);
}
.border-2 {
  border-width: 2px !important;
}
.side_distance {
  padding-left: 90px;
  padding-right: 100px;
  max-width: 1500px;
}

.contact_link {
  color: white;
  text-decoration: none;
}

.contact_link_active,
.contact_link:hover {
  color: $warning !important;
  text-decoration: none;
}

.active,
.contact:hover {
  border-bottom: 2px solid $warning !important;
  color: $warning !important;
}

@media (max-width: 576px) {
  // .darkBG {
  //   background-color: $primary;
  // }
  .side_distance {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
</style>
