import { mapGetters, mapActions } from "vuex";

export default {
    methods: {
        ...mapActions(["setIncludeFees"]),
    },
    computed: {
        ...mapGetters([
            "getIncludeFees"
        ]),
        includeFees: {
            get() {
              return this.getIncludeFees;
            },
            set(val) {
              this.setIncludeFees(val)
            }
          },      
    },
}