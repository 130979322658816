import axios from "axios";
import i18n, { loadLanguageAsync } from "../i18n";
import {
  CACHE_KEY_LANG,
  CACHE_KEY_VERSION,
  CACHE_KEY_COUNTRIES,
  CACHE_KEY_CURRENCIES
} from "@/config/storage";
//import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import merge from "lodash/merge";
import router from './../router/index'
//This store module handles the user, as well as the countries and currencies
//The user object is the username, email, name, location etc.
//The countries are all the countries in the DB
//The currencies are all the active currencies in the DB

export default {
  state: {
    user: null,
    country: null,
    countries: [],
    currencies: [],
    currency: null,
    currencySign: null,
    error: null,
    lang: "en",
    currencyCulture: null
  },
  getters: {
    user(state) {
      return state.user;
    },
    userID(state) {
      if (state.user) {
        return state.user.UserId;
      } else {
        return null;
      }
    },
    userFirstName(state) {
      if (state.user.Contact) {
        return state.user.Contact.FirstName;
      } else return null;
    },
    userLastName(state) {
      if (state.user.Contact) {
        return state.user.Contact.LastName;
      } else return null;
    },
    userAddress1(state) {
      if (state.user.Contact) {
        return state.user.Contact.AddressLine;
      } else return null;
    },
    userAddress2(state) {
      if (state.user.Contact) {
        return state.user.Contact.AddressLine2;
      } else return null;
    },
    userAddress(state) {
      if (state.user.Contact) {
        return state.user.Contact.AddressLine;
      } else return null;
    },
    userCity(state) {
      if (state.user.Contact) {
        return state.user.Contact.City;
      } else return null;
    },
    userProvince(state) {
      if (state.user.Contact) {
        return state.user.Contact.Province;
      } else return null;
    },
    userCountry(state) {
      if (state.user.Contact.Country) {
        return state.user.Contact.Country.Name;
      } else return null;
    },
    userCountryCode(state) {
      if (state.user.Contact.Country) {
        return state.user.Contact.Country.Code;
      } else return null;
    },
    userPostalCode(state) {
      if (state.user) {
        return state.user.Contact.ZipCode;
      } else return null;
    },
    userEmail(state) {
      if (state.user) {
        return state.user.Contact.Email;
      } else return null;
    },
    userType(state) {
      if (state.user) {
        return state.user.Roles;
      }
    },
    userMobileCountryCode(state) {
      if (state.user) {
        return state.user.MobileCountryCode;
      } else return null;
    },
    userMobile(state) {
      if (state.user) {
        return state.user.Contact.Mobile;
      } else return null;
    },
    country(state) {
      return state.country;
    },
    activeCountries(state) {
      //Returns the countries that are Active
      if (state.countries) {
        let active = state.countries.filter(el => el.Active == true);
        return active;
      } else return null;
    },
    activeBuyerCountries(state) {
      //Returns the countries that can buy cars
      if (state.countries) {
        let active = state.countries.filter(el => el.CanBuy == true);
        return active;
      } else return null;
    },
    activeSellerCountries(state) {
      //Returns the countries that can sell cars
      if (state.countries) {
        let active = state.countries.filter(el => el.CanSell == true);
        return active;
      } else return null;
    },
    currency(state) {
      return state.currency;
    },
    currencies(state) {
      return state.currencies;
    },
    currencyForSeller(state) {
      if (state.currencies && state.userCountryCode) {
        let userCurrency = state.currencies.filter(
          el => el.CountryCode === state.userCountryCode
        );
        return userCurrency.Code;
      } else return "CAD";
    },
    currenciesForBuyer(state) {
      //Returns the currencies that can buy cars
      if (state.currencies) {
        let active = state.currencies.filter(el => el.Buyer || el.IsGlobal),
          check = new Set();
        return active.filter(
          obj =>
            !check.has(obj["CurrencyName"]) && check.add(obj["CurrencyName"])
        );
      } else return null;
    },
    countries(state) {
      return state.countries;
    },
    lang(state) {
      return state.lang;
    },
    currencyCulture(state) {
      return state.currencyCulture;
    },
    async getCountryByCode({getters}, payload = { Code: undefined }) {
      const selectedCountry = getters.activeBuyerCountries().find(
          (item) => item.Code === payload.Code
      );
      return selectedCountry
    },

  },
  actions: {
    user({ commit }, user) {
      commit("user", user);
    },
    setCountry({ commit }, country) {
      if (country) {
        localStorage.setItem("country", JSON.stringify(country)); //Stores the country in the browser and state
      }
      commit("country", country);
    },
    setLang({ commit }, lang) {
      axios.defaults.headers.common["languageCode"] = lang;
      localStorage.setItem("locale", lang);
      commit("lang", lang);
    },
    setCurrency({ commit, rootState }, currency) {
      try {
        if (currency === 'XAF' && (['CI', 'SN'].includes(rootState.search.destinationCountry.Code))) {
          currency = 'XOF';
        }
      }catch (e){
        console.log(e)
      }

      localStorage.setItem("currency", currency);
      commit("currency", currency);
    },
    currencyCulture({ commit }, currencyCulture) {
      commit("currencyCulture", currencyCulture);
    },
    async userFromApi(context, token) {
      //Takes the token from login or localstorage and returns the user
      //Also sets the users info, country, currency etc.
      let config = {
        headers: {
          accesstoken: token
        }
      };
      try {
        const response = await axios.get("user/GetCurrentUser", config);
        let user = response.data;
        console.log("user: ", user);
        context.commit("user", user);

        //Not used for now
        if (user.LangCode) {
          await loadLanguageAsync(user.LangCode);
          await context.dispatch("setLang", user.LangCode);
          await context.dispatch("countriesFromApi", user.LangCode);
        }
        if (user.Currency) {
          await context.dispatch("setCurrency", user.Currency.Code);
        }
      } catch (error) {
        context.commit("error", error);
      }
    },
    async updateUser(context, Contact) {
      //Writes to the current user in the DB, with params in the component
      let token = localStorage.getItem("token");
      if (token) {
        //   let config = {
        //     headers: {
        //       accesstoken: token
        //     }
        //   };
        try {
          const response = await axios.post("user/SetUser", {
            accesstoken: token,
            Contact
          });
          response;
          context.dispatch("userFromApi", token);
        } catch (error) {
          context.commit("error", error);
        }
      }
    },
    async countriesFromApi({ commit, getters, dispatch }) {
      if (process.env.NODE_ENV === "development") console.log("get countries");
      try {
        const cacheCountries = this._vm.$storage.get(CACHE_KEY_COUNTRIES);
        let countries = [];
        if (!cacheCountries) {
          const response = await axios.get("Basic/GetCountries");
          countries = response.data.slice(0).sort(function(a, b) {
            var x = a.Name.toLowerCase();
            var y = b.Name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          this._vm.$storage.set(CACHE_KEY_COUNTRIES, countries);
          commit("countries", countries);
          dispatch("destCountryChangeLang");
        } else {
          commit("countries", cacheCountries);
        }
        let current = getters.country;
        if (current) {
          //Matches the current country to the list by its Code, necessary when language changes
          current = countries.find(el => el.Code == current.Code);
          await dispatch("setCountry", current);
        }
      } catch (error) {
        console.log(error);
        commit("error", error);
      }
    },
    async currenciesFromApi({ commit }) {
      if (process.env.NODE_ENV === "development") console.log("get currencies");
      //Gets the currencies
      try {
        const cacheCurrencies = this._vm.$storage.get(CACHE_KEY_CURRENCIES);
        if (!cacheCurrencies) {
          const response = await axios.get("Basic/GetCurrencies");
          commit("currencies", response.data);
          this._vm.$storage.set(CACHE_KEY_CURRENCIES, response.data);
        } else {
          commit("currencies", cacheCurrencies);
        }
      } catch (error) {
        commit("error", error);
      }
    },
    async getCurrentCountry({ dispatch, state }, payload) {
      if (process.env.NODE_ENV === "development") console.log("get current country");
      //Gets the currentCountry
      const response = await axios.get("Basic/GetLocationByIP");
      const country = response.data.location.country;
      //set country
      let selectedCountry = state.countries.find((el) => el.Code == country.code);
      dispatch("setCountry", selectedCountry);
      //set currency
      const currency = localStorage.getItem("currency")
      if(!currency || payload.forceChangeCurrency) {
        let selectedCurrency = state.currencies.find((el) => el.CountryCode == country.code);
        dispatch("setCurrency", selectedCurrency.Code);
      }
      dispatch("getCurrencyCulture", payload.locale);
    },
    async languagesFromApi() {
      if (process.env.NODE_ENV === "development") console.log("get lang");
      const langMessages = this._vm.$storage.get(CACHE_KEY_LANG);
      //let messages = cloneDeep(i18n.messages[i18n.locale]);
      const locale = localStorage.getItem("locale") || (window.navigator.language.substr(0,2) || 'en');
      let messages = {};
      if (!langMessages || !i18n.messages[locale] || Object.keys(i18n.messages[locale]).length <= 0) {
        const response = await axios.post("Basic/Resource", {
          Categories: [
            "FrontEnd.HelpCenter",	
            "FrontEnd.Legal",
            "FrontEnd.Registration",
            "FrontEnd.Warranty",
            "FrontEnd.Newsletter",
            "FrontEnd.ListingProcess",
            "FrontEnd.PageTitle",
            "FrontEnd.Reservation",
            "FrontEnd.Overview",
            "FrontEnd.Validations",
            "FEM.accountSettings.button",
            "FEM.accountSettings.label",
            "FEM.carcard.button",
            "FEM.carcard.help",
            "FEM.carcard.label",
            "FEM.carDetail.button	",
            "FEM.carDetail.label",
            "FEM.contactInfo.label",
            "FEM.dashboard.label",
            "FEM.dashboard.button",
            "FEM.footer.label",
            "FEM.global.button",
            "FEM.global.label",
            "FEM.global.message",
            "FEM.homepage.body",
            "FEM.homepage.findmycar",
            "FEM.homepage.header",
            "FEM.homepage.label",
            "FEM.langPopup.label",
            "FEM.listing.button",
            "FEM.listing.label",
            "FEM.listings.label",
            "FEM.myPurchases.label",
            "FEM.navBar.label",
            "FEM.navBar.link",
            "FEM.notFound.label",
            "FEM.notifications.label",
            "FEM.purchases.label",
            "FEM.register.label",
            "FEM.reservation.label",
            "FEM.search.button",
            "FEM.search.label",
            "FEM.searchBar.label",
            "FEM.signIn.button",
            "FEM.signIn.label",
            "FEM.topbar.button",
            "FEM.topbar.label",
            "FrontEnd.AboutPash",
            "FrontEnd.Buy",
            "FrontEnd.CarDetails",
            "FrontEnd.Contact",
            "FrontEnd.Currency",
            "FrontEnd.Footer",
            "FrontEnd.FreightForwarders",
            "FrontEnd.Header",
            "FrontEnd.HelpCenter",
            "FrontEnd.InspectionAgents",
            "FrontEnd.Legal",
            "FrontEnd.ListingProcess",
            "FrontEnd.Message",
            "FrontEnd.Modal",
            "FrontEnd.Newsletter",
            "FrontEnd.Overview",
            "FrontEnd.PageTitle",
            "FrontEnd.Registration",
            "FrontEnd.Reservation",
            "FrontEnd.SalesRepresentatives",
            "FrontEnd.Sell",
            "FrontEnd.Validations",
            "FrontEnd.Warranty",
            "FrontEnd.WaysToWorkWithPash",
            "FrontEnd.WorkWithUs"
          ]
        });
        response.data.forEach(item => {
          const Keys = item["Resources"].reduce((accum, record) => {
            return { ...accum, [record.Key]: record.Value };
          }, {});
          let nameKey = item["Name"];
          nameKey = nameKey.replace("FrontEnd.", "");
          nameKey = nameKey.replace("FEM.", "");
          nameKey = nameKey.split(".");
          const renderedMessages = Object.keys(Keys).reduce((accum, key) => {
            const modifiedKey =
            nameKey.length > 1 ? `${nameKey[1]}.${key}` : key;
            set(accum, modifiedKey, Keys[key]);
            return accum;
          }, {});
          if (messages[nameKey[0]]) {
            messages[nameKey[0]] = merge(
              messages[nameKey[0]],
              renderedMessages
              );
            } else {
              messages[nameKey[0]] = renderedMessages;
            }
          });
          this._vm.$storage.set(CACHE_KEY_LANG, messages);
          i18n.setLocaleMessage(locale, messages);
          i18n.locale = locale;
        } else {
          console.log(locale, i18n)
        messages = langMessages;
        i18n.setLocaleMessage(locale, messages);
        i18n.locale = locale;
      }

    },
    async resourceVersionFromApi({ dispatch }) {
      const version = await this._vm.$storage.get(CACHE_KEY_VERSION);
      const response = await axios.get("Basic/GetLastVersion", {});
      if (process.env.NODE_ENV === "development")
        console.log("version checked");
      if (response.data !== version) {
        if (process.env.NODE_ENV === "development")
          console.log("version not found");
        await dispatch("removeAllCache");
        await this._vm.$storage.set(CACHE_KEY_VERSION, response.data);
      }
    },
    async removeAllCache() {
      if (process.env.NODE_ENV === "development") console.log("remove cache");
      await this._vm.$storage.remove(CACHE_KEY_CURRENCIES);
      await this._vm.$storage.remove(CACHE_KEY_LANG);
      await this._vm.$storage.remove(CACHE_KEY_COUNTRIES);
    },
    async refetchAllCache({ dispatch }) {
      await dispatch("removeAllCache");
      await dispatch("resourceVersionFromApi");
      await dispatch("languagesFromApi");
      await dispatch("countriesFromApi");
      await dispatch("currenciesFromApi");
    },
    async getCurrencyCulture({ commit }, lang) {
      let currencyCulture = lang + "-" + "EN";

      commit("currencyCulture", currencyCulture);
    },
    async resendEmail(){
      router.push({ name: "RegisterPage" });
      try {
        await axios.post("user/EmailResendCode", {
          LoginKey: localStorage.getItem("token"),
        });
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    }
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    error(state, error) {
      state.error = error;
    },
    country(state, country) {
      state.country = country;
    },
    currencySign(state, sign) {
      state.currencySign = sign;
    },
    currency(state, currency) {
      if (state.currencies) {
        let active = state.currencies.filter(el => el.Buyer || el.IsGlobal),
        check = new Set();
        active.filter(obj => !check.has(obj["CurrencyName"]) && check.add(obj["CurrencyName"]));
        console.log('currency',active, currency)
        if(!active.find(item => item.Code === currency)){
          currency = 'CAD'
        }
      }
      //console.log(currency)
      axios.defaults.headers.common["currencyCode"] = currency;
      state.currency = currency;
    },
    currencies(state, currencies) {
      state.currencies = currencies;
    },
    countries(state, countries) {
      state.countries = countries;
    },
    lang(state, lang) {
      state.lang = lang;
    },
    currencyCulture(state, value) {
      state.currencyCulture = value;
    }
  }
};
