import parsePhoneNumber from "libphonenumber-js";
import axios from "axios";

export default {
    data() {
        return {
            username: localStorage.getItem("username"),
            country: "ca",
            countries: [
              { value: "ca", text: "Canada" },
              { value: "us", text: "USA" },
              { value: "mx", text: "Mexico" },
            ],
            emailFormat: /\S+@\S+\.\S+/,
            mobilePayload: null,
            mobileID: null,
            availableEmail: null,
            availableMobile: null,
            errorMessage: null
        }
    },
    // watch: {
    //     isMobile() {
    //       this.checkMobile();
    //     },
    // },
    computed: {
        isMobile() {
      // if(this.username) {
      //   if(!isNaN(this.username) && this.username.length >= 10) {return true;} else return false
      // }
      if (this.mobilePayload) {
        if (this.mobilePayload.isValid) {
          return true;
        } else return false;
      } else {
        this.availableMobile = null
        return false;
      }
    },
            switchInput() {
        if (this.username) {
            if (/^[a-zA-Z]+$/.test(this.username.charAt(0))) {
              return false;
            } else {
              this.availableEmail = null
              this.availableMobile = null
              return true;
            }
          }
        return false;
        },
        isEmail() {
            if (this.username) {
              if (this.emailFormat.test(this.username)) {
                return true;
              } else {
                this.availableEmail = null;
                return false;
              }
            } else {
              this.availableEmail = null;
              return false;
            }
          }
    },
    methods: {
        async checkEmail() {
          this.availableEmail = null;
          try {
            if (this.isEmail) {
              const response = await axios.post("user/IsEmailExist", {
                Email: this.username,
              });
              this.availableEmail = response.data;
            } else this.availableEmail = false;
          } catch (error) {
            this.availableEmail = false;
            this.errorMessage = error.response.data;
            console.log(error.response.data);
          }
        },
        async checkMobile() {
          this.availableMobile = null;
          if (this.isMobile) {
            try {
              const response = await axios.post("user/IsMobileExist", {
                Mobile: this.mobilePayload.formattedNumber,
              });
              this.availableMobile = response.data;
            } catch (error) {
              this.availableMobile = false;
              this.errorMessage = error.response.data;
              console.log(error.response.data);
            }
          } else this.availableMobile = false;
        },
        focusTo() {
          if (this.switchInput && this.username) {
            if (this.username.length === 1) {
              let l = document.getElementsByTagName("input");
              for (let i = 0; i < l.length; i++) {
                if (l[i].className === "input-tel__input") {
                  this.mobileID = l[i].id;
                }
              }
              document.getElementById(this.mobileID).focus();
            }
          }
        },
        setMobilePayload(event) {
          if(!event.phoneNumber) return false
          const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);
          if(mobileNumber) {
            const payload = {
              ...mobileNumber,
              isValid: mobileNumber ? mobileNumber.isValid() : false,
              countryCallingCode: mobileNumber.countryCallingCode,
              formatInternational: mobileNumber.formatInternational(),
              formatNational: mobileNumber.number,
              formattedNumber: mobileNumber.number,
              uri: mobileNumber.getURI(),
              e164: mobileNumber.ext
            }
            this.mobilePayload = payload;
          }
        },
    },
    created() {
      window.addEventListener("keydown", (e) => {
        if (e.key && this.mobileID) {
          this.focusTo();
        }
      });
    },
    beforeDestroy() {
      window.removeEventListener("keydown", (e) => {
        if (e.key && this.mobileID) {
          this.focusTo();
        }
      });
    }
}