export default {
    data() {
        return {
            buyerLinks: [
                {
                    link:'/' + this.$t('Buy.Link.Search'),
                    text: this.$t("Footer.Footer.SearchCars")
                },
                {
                    link:
                        {
                            name: 'HelpInformation',
                            params: {
                          subsection: this.$t('Footer.Footer.Buyers').toLowerCase(),
                          type: this.$t('HelpCenter.Buyer.BuyWithUs')
                            .replaceAll(' ', '-')
                            .toLowerCase()
                        }
                    },
                    text: this.$t('Footer.Footer.HowToBuy'),
                    anchor: 'servicesAnchor'
                },
                {
                    link:'/' + this.$t('navBar.label.buy'),
                    text: this.$t("Footer.Footer.Advantages"),
                    anchor: 'advantagesAnchor'
                },
                {
                    link:'/' + this.$t('Contact.Link.ContactRepresentatives'),
                    text: this.$t("Footer.Footer.SalesRepresentative")
                },
                {
                    link: {
                        name: 'HelpCenterSubsection',
                        params: {
                            subsection: this.$t("Footer.Footer.Buyers").toLowerCase()
                        }
                    },
                    text: `${ this.$t("Footer.NeedHelp") }`
                }
            ]
        }
    }
}