<template>
    <div v-if="subBanner" id="subheader" class="text-center w-100 d-flex flex-direction-column align-items-center justify-content-center p-2 fs-14">
        <div class="d-flex">
            <div :class="readMore ? '' : 'd-flex'">
                <p class="mb-0 fs-13">
                    {{ readMore ? $t("Header.SubBanner.ComingSoon") : filter($t("Header.SubBanner.ComingSoon"), 45) }}
                    <a href="#" v-if="readMore" @click="deliveryCountryNotFoundModal">
                        {{ $t("Contact.LeaveContact") }}
                    </a>
                    {{ readMore ? $t("Header.SubBanner.ComingSoon2") : '' }}
                    <a href="#" v-if="readMoreBtn" @click="handleReadMore">
                        {{ readMore ? $t("Header.ReadLess") : $t("Header.ReadMore") }}
                        <span v-if="readMore">
                            <ChevronUp/>
                        </span>
                        <span v-else>
                            <ChevronDown/>
                        </span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';

export default {
    name: "TheSubHeader",
    components: {
        ChevronUp,
        ChevronDown
    },
    data() {
        return {
            subBanner: false,
            readMore: false,
            readMoreBtn: true,
        }
    },
    computed: {
        ...mapGetters([
          "activeBuyerCountries"
        ]),
        ...mapGetters({ currentCountry: "country" }),
    },
    watch: {
        currentCountry() {
            const activeCountriesToCheck = this.$route.name === "Sell" ? this.activeSellerCountries() : this.activeBuyerCountries;
            this.subBanner = this.displaySubBanner(activeCountriesToCheck)
        },
        $route(newVal, oldVal){
            const activeCountriesToCheck = this.$route.name === "Sell" ? this.activeSellerCountries() : this.activeBuyerCountries;
            this.subBanner = this.displaySubBanner(activeCountriesToCheck)
        },
    },
    created() {
        this.subBanner = this.displaySubBanner(this.activeBuyerCountries)
        if (window.innerWidth >= 1200) {
            this.readMore = true;
            this.readMoreBtn = false;
        }
    },
    methods: {
        activeSellerCountries() {
          return this.$store.getters.activeSellerCountries;
        },
        currentCountryIsActive(activeCountries) {
            return activeCountries.some((country) => country.Code === this.currentCountry.Code)
        },
        deliveryCountryNotFoundModal() {
            this.$bvModal.show('deliveryCountryNotFound');
        },
        displaySubBanner(activeCountriesToCheck) {
            return !this.currentCountryIsActive(activeCountriesToCheck) && !(this.$route.path.includes('search') || this.$route.path.includes('recherche'))
        },
        // isWorkPage(route) {
        //     const splitRouteName = route.path.split('/')
        //     const routeName = splitRouteName[splitRouteName.length - 1]
        //     const routesToCheck = [
        //         "search", "work-with-us", "inspection-agents", "freight-forwarders", 
        //         "customs-brokers", "sales-representatives", "jobs"
        //     ]
        //     return routesToCheck.includes(routeName)
        // },
        filter (text, length, clamp) {
            clamp = clamp || '...';
            var node = document.createElement('div');
            node.innerHTML = text;
            var content = node.textContent;
            return content.length > length ? content.slice(0, length) + clamp : content;
        },
        handleReadMore() {
            this.readMore = !this.readMore;
        }
    }
}
</script>

<style lang="scss">
#subheader {
    background-color: #FCF1D4;
    @media screen and (max-width: 1060px) {
    }
}
</style>