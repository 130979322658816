export default {
    data() {
        return {
            opened: false
        }
    },
    methods: {
        handleOpened(noScroll) {
            this.opened = !this.opened;
            var body = document.getElementsByTagName("body")[0];
            if (noScroll) {
                body.classList.add("modal-open")
            } else body.classList.remove("modal-open")
        }
    }
}