<template>
  <b-modal
    id="sellerChecking"
    hide-footer
    hide-header
    centered
    no-stacking
    no-close-on-backdrop
    size="md"
  >
    <template #default="{ hide }">
      <b-row>
        <b-col cols="12">
          <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
        </b-col>
        <b-col cols="12">
          <b-card>
            <p
              :class="{
                'text-success': mobileValidated,
                'text-danger': !mobileValidated
              }"
            >
              <b-icon icon="check-circle-fill" v-show="mobileValidated"></b-icon
              ><b-icon icon="x-circle-fill" v-show="!mobileValidated"></b-icon>
              Have a validated mobile
            </p>
            <p
              :class="{
                'text-success': emailValidated,
                'text-danger': !emailValidated
              }"
            >
              <b-icon icon="check-circle-fill" v-show="emailValidated"></b-icon
              ><b-icon icon="x-circle-fill" v-show="!emailValidated"></b-icon>
              Have a validated email
            </p>
            <p
              :class="{
                'text-success': authorisedCountry,
                'text-danger': !authorisedCountry
              }"
            >
              <b-icon
                icon="check-circle-fill"
                v-show="authorisedCountry"
              ></b-icon
              ><b-icon
                icon="x-circle-fill"
                v-show="!authorisedCountry"
              ></b-icon>
              User country that are authorised to sell
            </p>
            <p
              :class="{
                'text-success': userType,
                'text-danger': !userType
              }"
            >
              <b-icon icon="check-circle-fill" v-show="userType"></b-icon
              ><b-icon icon="x-circle-fill" v-show="!userType"></b-icon> User
              type that are authorised to sell
            </p>
          </b-card>
        </b-col>
        <b-col class="text-center p-3"
          ><b-button pill variant="danger" @click="hide()"
            >Cancel</b-button
          ></b-col
        >
        <b-col class="text-center p-3"
          ><b-button pill variant="primary" @click="go()"
            >Finish setup</b-button
          ></b-col
        >
      </b-row>
      <!-- {{ $store.getters.user }} -->
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      mobileValidated: false,
      emailValidated: false,
      userType: true,
      authorisedCountry: false
    };
  },
  methods: {
    go() {
      this.$bvModal.hide("sellerChecking");
      this.$router.push({ name: "PrimaryContactEdit" });
    },
    check() {
      let user = this.$store.getters.user;
      this.$store.dispatch("verifyForListing", false);
      if (user) {
        this.mobileValidated = user.IsMobileVerified;
        this.emailValidated = user.IsEmailVerified;
        this.authorisedCountry = user.Contact.Country.CanSell;
        // this.userType = user.Roles.includes("Seller");
      }
      if (
        this.emailValidated &&
        this.mobileValidated &&
        this.authorisedCountry &&
        this.userType
      ) {
        this.$store.dispatch("verifyForListing", true);
      }
    }
  },
  beforeMount() {
    this.check();
  }
};
</script>
