<template>
  <div class="bg-secondary">
    <b-modal
      id="forgotPassword"
      ref="modalForgotPassword"
      hide-footer
      hide-header
      centered
      no-stacking
      :no-close-on-backdrop="true"
      size="lg"
    >
      <template #default="{ hide }">
        <b-row>
          <b-col class="p-0 d-none d-lg-block">
            <div class="fixed-background">
              <div class="text-center text-light">
                <h2>Hey, Welcome to Pash!</h2>
              </div>
              <div class="fixed-wrap">
                <div class="fixed">
                  <div class="signIn_cover"></div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="pl-0">
            <div>
              <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
            </div>
            <div class="m-auto col-10 text-center pt-4">
              <h3>Forgot Passwords</h3>
              <p>
                Please enter your email address below. If it exists in our
                system a message will be sent with further instructions.
              </p>
            </div>

            <form @submit.prevent="passwordSubmit" v-show="!sended">
              <div class="col-10 m-auto">
                <b-form-input
                  type="email"
                  class="input-pills"
                  v-model="email"
                  @blur="checkEmail"
                  :state="availableEmail"
                  :placeholder="$t('signIn.label.email')"
                ></b-form-input>
                <div v-show="email && availableEmail !== null">
                  <b v-show="!availableEmail" class="text-danger m-0 mini-font">
                    <b-icon icon="x-circle-fill"></b-icon> Email is not in use
                    at Pash!
                  </b>
                </div>
                <b-button
                  pill
                  size=""
                  variant="primary"
                  class="w-100 mt-3"
                  type="submit"
                >
                  Send Password Reset
                </b-button>
              </div>
            </form>
            <div class="text-center">
              <vac
                ref="forgotPassword_countdown"
                :autoStart="false"
                :left-time="5 * 1000"
                @finish="finish()"
                v-show="sended"
              >
                <template v-slot:process="{ timeObj }">
                  <h3 class="text-success">
                    Requires has been sended sucesssfully!
                  </h3>
                  <p>
                    Password reset message has been sent to your email address
                    with further instructions.
                  </p>
                  <h6>
                    {{ `You will be redirect within ${timeObj.s} seconds!` }}
                  </h6>
                </template>
                <template v-slot:finish>
                  <h3>You are now redirect!</h3>
                </template>
              </vac>
            </div>

            <div class="col-10 pt-5 m-auto">
              <b-button
                pill
                size=""
                variant="outline-primary"
                class="w-100"
                type=""
                v-b-modal.signin
                @click="hide()"
              >
                Back to Sign In
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      email: null,
      availableEmail: null,
      sended: false
    };
  },
  methods: {
    async passwordSubmit() {
      await axios.post("user/ResetPasswordSendCode", {
        EMail: this.email
      });
      this.sended = true;
      this.startCountdown();
    },
    async checkEmail() {
      this.availableEmail = null;
      try {
        if (/\S+@\S+\.\S+/.test(this.email)) {
          // const response = await axios.post("SP/post/sp_User_Validation", {
          //   Email: this.email
          // });
          // let availability = JSON.parse(response.data.result).Table[0].value;
          // if (availability === 1) this.availableEmail = false;
          // if (availability === 0) this.availableEmail = true;
          const response = await axios.post("user/IsEmailExist", {
            Email: this.email
          });
          this.availableEmail = !response.data;
        }
      } catch (error) {
        this.availableEmail = false;
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    startCountdown() {
      const vm = this;
      vm.$refs.forgotPassword_countdown.startCountdown(true);
    },
    finish() {
      this.$bvModal.hide("forgotPassword");
    }
  }
};
</script>
