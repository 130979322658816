import axios from "axios";
import * as http from "http";

// Un-comment the environment you are using.

// DEV
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.maxRedirects = 0
axios.defaults.httpAgent = new http.Agent({ keepAlive: true }),


// PRE-PROD
// axios.defaults.baseURL = "https://ppmatrix.pashmotor.com/v1/";

// LIVE
// axios.defaults.baseURL = "https://matrix.pashmotor.com/v1/";

axios.defaults.headers.common["languageCode"] = localStorage.getItem("locale") || (window.navigator.language.substr(0,2) || 'en');

axios.defaults.headers.common["timeZone"] = new Date().getTimezoneOffset();
axios.defaults.headers.common["defaultTotalFeeId"] = 44;

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["accesstoken"] = localStorage.getItem("token");
}
