var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"sellerChecking","hide-footer":"","hide-header":"","centered":"","no-stacking":"","no-close-on-backdrop":"","size":"md"},scopedSlots:_vm._u([{key:"default",fn:function({ hide }){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"close px-2 pb-2",on:{"click":function($event){return hide()}}},[_vm._v("x")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('p',{class:{
              'text-success': _vm.mobileValidated,
              'text-danger': !_vm.mobileValidated
            }},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileValidated),expression:"mobileValidated"}],attrs:{"icon":"check-circle-fill"}}),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileValidated),expression:"!mobileValidated"}],attrs:{"icon":"x-circle-fill"}}),_vm._v(" Have a validated mobile ")],1),_c('p',{class:{
              'text-success': _vm.emailValidated,
              'text-danger': !_vm.emailValidated
            }},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.emailValidated),expression:"emailValidated"}],attrs:{"icon":"check-circle-fill"}}),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.emailValidated),expression:"!emailValidated"}],attrs:{"icon":"x-circle-fill"}}),_vm._v(" Have a validated email ")],1),_c('p',{class:{
              'text-success': _vm.authorisedCountry,
              'text-danger': !_vm.authorisedCountry
            }},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.authorisedCountry),expression:"authorisedCountry"}],attrs:{"icon":"check-circle-fill"}}),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.authorisedCountry),expression:"!authorisedCountry"}],attrs:{"icon":"x-circle-fill"}}),_vm._v(" User country that are authorised to sell ")],1),_c('p',{class:{
              'text-success': _vm.userType,
              'text-danger': !_vm.userType
            }},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.userType),expression:"userType"}],attrs:{"icon":"check-circle-fill"}}),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.userType),expression:"!userType"}],attrs:{"icon":"x-circle-fill"}}),_vm._v(" User type that are authorised to sell ")],1)])],1),_c('b-col',{staticClass:"text-center p-3"},[_c('b-button',{attrs:{"pill":"","variant":"danger"},on:{"click":function($event){return hide()}}},[_vm._v("Cancel")])],1),_c('b-col',{staticClass:"text-center p-3"},[_c('b-button',{attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.go()}}},[_vm._v("Finish setup")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }