<template>
  <b-modal
    :id="modalId"
    ref="select-delivery-country-currency"
    dialog-class="select-delivery-country-currency"
    centered
    hide-footer
    @shown="onShowModal"
    :no-close-on-backdrop="!fullControl"
    :no-close-on-esc="!fullControl"
    :no-enforce-focus="!fullControl"
    :hide-header-close="!fullControl"
  >
    <template #modal-header="{hide}">
      <h1>
        {{ $t("Modal.DeliveryCountry.title") }}
      </h1>
      <b-button class="close" @click="hide" v-if="fullControl">
        <b-icon icon="x" />
      </b-button>
    </template>
    <template #default>
      <div class="field descriptions">
        {{ $t("Modal.DeliveryCountry.descriptions") }}
      </div>
      <div class="field">
        <label>
          {{ $t("Modal.DeliveryCountry.DeliveryCountryLabel") }}
        </label>
        <b-dropdown
          variant="outline-primary"
          class="bg-white inputSelectDropdown"
          menu-class="w-100"
        >
          <template #button-content>
            <div
              class="inputSelectDropdownPlaceholder"
              :class="{ inputSelectDropdownPlaceholderHasVal: selectedCountry }"
            >
              <div v-if="selectedCountry" class="inputSelectDropdownValue">
                {{ selectedCountry.Name }}
              </div>
              <span class="inputSelectLabel" v-else>
                {{ $t("Modal.DeliveryCountry.DeliveryCountryPlaceholder") }}
              </span>
            </div>
          </template>
          <b-dropdown-header>
            {{ $t("Buy.Header.SelectCountry") }}
          </b-dropdown-header>
          <b-dropdown-item
            v-for="country in activeBuyerCountries"
            :key="country.Code"
            @click="selectedCountry = country"
          >
            {{ country.Name }}
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item-button
            variant="primary"
            @click="selectedCountry = 'noCountry'"
          >
            {{ $t("topbar.button.noCountry") }}
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div class="field">
        <label>
          {{ $t("Modal.DeliveryCountry.DeliveryCurrencyLabel") }}
        </label>
        <b-dropdown
          variant="outline-primary"
          class="bg-white inputSelectDropdown"
        >
          <template #button-content>
            <div
              class="inputSelectDropdownPlaceholder"
              :class="{
                inputSelectDropdownPlaceholderHasVal: selectedCurrency,
              }"
            >
              <div class="inputSelectDropdownValue" v-if="selectedCurrency">
                <span v-if="['XAF', 'XOF'].includes(selectedCurrency)">
                  FCFA
                </span>
                <span v-else>{{ selectedCurrency }}</span>
              </div>
              <span class="inputSelectLabel" v-else>
                {{ $t("Modal.DeliveryCountry.DeliveryCurrencyPlaceholder") }}
              </span>
            </div>
          </template>
          <b-dropdown-header>
            {{ $t("Buy.Header.SelectCurrency") }}
          </b-dropdown-header>
          <b-dropdown-item
            v-for="cfb in currenciesForBuyer"
            :value="cfb.Code"
            :key="cfb.Code"
            @click="selectedCurrency = cfb.Code"
          >
            <span v-if="cfb.Sign === 'FCFA'">{{ cfb.Sign }}</span>
            <span v-else>{{ cfb.Code }}</span> - {{ cfb.CurrencyName }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="field" id="deliveryModalCheckbox">
        <b-form-checkbox
          v-model="includeFeesOption"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
        >
          {{$t('Overview.fees.included')}}
          <b-icon icon="info-circle" />
        </b-form-checkbox>
      </div>
      <b-tooltip
      target="deliveryModalCheckbox"
              triggers="hover"
              placement="bottom"
              variant="dark"
              ><span style="font-size:10px;">
                {{ $t('Buy.Search.PopupMessage') }}
              </span></b-tooltip
            >
      <div class="field text-right mb-0">
        <b-btn
          size="large"
          variant="primary"
          @click="searchSubmit"
          class="delivery-continue-btn"
        >
          {{ $t("Modal.DeliveryCountry.DeliveryCurrencyOkBtn") }}
        </b-btn>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import includeFees from '@/components/mixins/includeFees.js'

export default {
  name: "select-delivery-country-currency",
  props:{
    modalId: {
      type: String,
      default: 'selectDeliveryCountryCurrency'
    },
    fullControl: {
      type: Boolean,
      default: false
    },
  },
  mixins: [includeFees],
  data() {
    return {
      includeFeesOption: undefined,
      selectedCountry: undefined,
      selectedCurrency: undefined,
    };
  },
  watch: {
    selectedCountry: function(selectedCountry) {
      //this.selectedCurrency = this.currenciesForBuyer.find(item => item.CountryCode === selectedCountry.Code).Code
      if (
        selectedCountry.Code === "CM" ||
        selectedCountry.Code === "CI" ||
        selectedCountry.Code === "SN"
      ) {
        this.selectedCurrency = "XAF";
      } else if (selectedCountry.Code === "CA") {
        this.selectedCurrency = "CAD";
      } else if (selectedCountry.Code === "US") {
        this.selectedCurrency = "USD";
      } else if (selectedCountry === "noCountry") {
        this.$bvModal.hide(this.modalId);
        this.$bvModal.show("deliveryCountryNotFound");
        this.selectedCountry = undefined;
      } else {
        this.selectedCurrency = "CAD";
      }
    },
  },
  methods: {
    ...mapActions(['setListingPrices']),
    //check on modal show
    onShowModal() {
      this.includeFeesOption = this.includeFees;
      const destinationCountry = this.$store.state.search.destinationCountry;
      if (destinationCountry) {
        this.selectedCountry = destinationCountry;
      }
      // get active buyer currencies
      const currenciesForBuyerCodes = this.currenciesForBuyer.map((item) =>
        item.Code.toLowerCase()
      );
      // check if current currency exist on active buyer codes set default
      if (
        this.currency &&
        currenciesForBuyerCodes.includes(this.currency.toLowerCase())
      ) {
        this.selectedCurrency = this.currency;
      }
    },
    async searchSubmit() {
      if (this.selectedCountry && this.selectedCurrency) {
        localStorage.setItem(
          "destCountry",
          JSON.stringify(this.selectedCountry)
        );

        this.$store.dispatch("destinationCountry", this.selectedCountry);
        this.$store.commit("currency", this.selectedCurrency);
        this.includeFees = this.includeFeesOption;
        localStorage.setItem("currency", this.selectedCurrency);
        //if on overview page, dont change page
        if (!this.$route.path.toLowerCase().includes(this.$t('navBar.link.Overview').toLowerCase())) {
          let newPath = `/${this.$t('Buy.Link.Search')}/${this.selectedCountry.Code}/${this.selectedCurrency}`
          if (this.$route.path === newPath) {
            let listingsPrices = await this.getTotalPrice();
            this.setListingPrices(listingsPrices.data)
          } else this.$router.push({
            path: newPath
          });
        } else {
          this.$router.go(0)
        }

        this.$bvModal.hide(this.modalId);
      } else {
        this.$store.dispatch("showToast", {
          content: this.$t("Validations.Search.NoCountryAndCurrency"),
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "currency",
      "currencies",
      "country",
      "currenciesForBuyer",
      "activeBuyerCountries",
      "listings",
      "getTotalPrice"
    ]),
    isBuyerCurrency() {
      let currencyArr = [];
      this.currenciesForBuyer.forEach((e) => {
        currencyArr.push(e.Code);
      });
      return currencyArr.includes(this.currency);
    },
    searchCountry: {
      get() {
        if (
          this.$store.state.user.country &&
          this.$store.state.user.country.CanBuy
        ) {
          return this.$store.getters.country;
        } else {
          return undefined;
        }
      },
      set(country) {
        if (country === "noCountry") {
          this.$bvModal.show("deliveryCountryNotFound");
        } else {
          this.searchCountryInput = country;
          this.$store.dispatch("destinationCountry", country);
        }
      },
    },
  },
  beforeCreate() {
        //check user's set duty/tax option
        this.includeFeesOption = this.includeFees;
    }
};
</script>

<style lang="scss">

.select-delivery-country-currency {
  width: 100% !important;
  max-width: 580px !important;
  .modal-content {
    padding: 1rem 2rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    @media (max-width: 575px) {
      padding: 1rem 1rem;
    }
    .inputSelectDropdown button {
      &:hover,
      &:active,
      &:focus,
      &[aria-expanded="true"] {
        color: #fff !important;
      }
    }
    .inputSelectDropdown .inputSelectLabel {
      transition: 0.3s all;
    }
    .inputSelectDropdownPlaceholder {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &.inputSelectDropdownPlaceholderHasVal {
        .inputSelectLabel {
          font-size: 10px;
          margin-top: -5px;
        }
        .inputSelectDropdownValue {
          line-height: 1;
          color: #333;
        }
      }
    }
    .inputSelectDropdown .dropdown-toggle {
      height: 35px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background: $light-blue;
        .inputSelectDropdownValue {
          color: white !important;
        }
      }
    }
    .descriptions {
      white-space: pre-line;
      font-size: 0.875rem;
    }

    .field {
      margin-bottom: 1.5rem;
      label {
        font-weight: bolder;
        display: block;
      }
      .inputSelectDropdown {
        width: 55%;
        @media (max-width: 575px) {
          width: 100%;
        }
        &.show {
          .inputSelectDropdownValue {
            color: white !important;
          }
        }
      }
    }
  }
  .modal-header {
    padding: 1rem 0;
    margin-bottom: 1rem;
    h1 {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      color: #000;
    }
    .close {
      width: 24px;
      height: 24px;
      background: #dbdbdb;
      border-radius: 100%;
      padding: 0 !important;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      margin: 0;
      svg {
        color: #777;
      }
    }
  }
}
.delivery-continue-btn {
  width: 25%;
  @media (max-width: 575px) {
    width: 100%;
  }
}
</style>
