export default {
    data() {
        return {
            sellerLinks: [
                {
                    link: '/' + this.$t('navBar.label.sell'),
                    text: this.$t("Footer.Footer.SellWithPash")
                },
                // {
                //     link: { name: 'listingOption' },
                //     text: this.$t("Footer.Footers.SellMyCars")
                // },
                {
                    link: '/' + this.$t('navBar.label.sell'),
                    text: this.$t("Footer.Footer.WhySellWithPash"),
                    anchor: 'whySellAnchor'
                },
                // {
                //     link: '/' + this.$t('Contact.Link.ContactRepresentatives'),
                //     text: this.$t("Footer.Footer.CarDealers"),
                // },
                // {
                //     link: '/' + this.$t('Contact.Link.ContactRepresentatives'),
                //     text: this.$t("Footer.Footer.PrivateSellers"),
                // },
                {
                    link: '/' + this.$t('navBar.label.sell'),
                    text: this.$t("Footer.Footer.HowToSellWithPash"),
                    anchor: 'howItWorkAnchor'
                }
            ]
        }
    }
}