//Tag ID Mapping
export const FilterTagMapping = {
  Year: 1,
  Make: 2,
  Model: 3,
  Mileage: 11,
  "Fuel type": 18,
  "Body type": 9,
  Drivetrain: 20,
  "Exterior color": 8,
  "Engine cylinder count": 24,
  "Number of passenger doors": 6,
  "Number of Seats": 7,
  "Transmission type": 21
};
