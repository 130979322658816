<template>
  <b-input-group class="m-0 phone-wrapper">
    <VuePhoneNumberInput
      class="col p-0"
      noExample
      v-model="mobile"
      :translations="{
        countrySelectorLabel: $t('Contact.countrySelectorLabel'),
        countrySelectorError: $t('Contact.countrySelectorError'),
        phoneNumberLabel: $t('Contact.phoneNumberLabel'),
        example: $t('Contact.example'),
      }"
      :default-country-code="
        $store.getters.country.Code ? $store.getters.country.Code : 'CA'
      "
      :only-countries="limitToCountry ? [limitToCountry] : null"
      @update="setMobilePayload($event)"
    />
    <!-- <b-input-group-append>
      <div v-if="!mobile" class="right-rounded border border-left-0 px-3" />
      <b-button
        @click="sendCode()"
        v-else-if="mobile"
        size="sm"
        class="right-rounded"
        :variant="
          (mobileValidated || currentMobile) && mobileIsValid
            ? 'success'
            : 'outline-danger'
        "
        :disabled="
          mobileValidated ||
            codeSent ||
            currentMobile ||
            (mobilePayload && !mobilePayload.isValid)
        "
        ><b v-if="mobilePayload && !mobilePayload.isValid">{{
          $t("Validations.mobile.incomplete")
        }}</b>
        <b v-else-if="codeSent">{{ $t("Validations.mobile.CodeSent") }}</b>
        <b v-else-if="mobileValidated || currentMobile">{{
          $t("Validations.mobile.validated")
        }}</b>
        <b v-else>{{ $t("Validations.mobile.validateNow") }}</b>
      </b-button>
    </b-input-group-append> -->
    <div class="col-12 mt-4">
      <div class="text-center">
          <p class="pb-2" v-if="codeSent">
            {{ $t("Validations.mobile.securityText") }}
          </p>
          <p class="pb-2" v-else>
            {{ $t("Validations.mobile.willSendCode") }}
          </p>
          <b class="text-danger" v-if="codeSent && codeInvalidText">
            {{ $t("Validations.mobile.CodeInvalid") }}
          </b>
          <div v-if="!codeSent">
            <b-button
              ref="validateMobileButton"
              class="mt-4 px-4 mx-auto"
              size="sm"
              variant="primary"
              pill
              @click="sendCode()"
              >{{ $t("Validations.mobile.validateNow") }}</b-button
            >
            <p class="text-danger fs-13 m-0">{{ errorMessage }}</p>
          </div>
        <div v-else>
          <CodeInput
          :loading="false"
          class="m-auto pb-4"
          v-on:complete="onComplete"
          ref="CodeInput"
          :fields="4"
        />
        
        <b-progress :value="progress" :max="60" />
        <b-button
          class="mt-4 px-4 mx-auto"
          size="sm"
          variant="primary"
          pill
          :disabled="!showResend"
          @click="reSendCode()"
          >{{ $t("Validations.mobile.resendCode") }}</b-button
          >
        </div>
      </div>
    </div>
  </b-input-group>
</template>

<script>
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import CodeInput from "vue-verification-code-input";
import parsePhoneNumber from "libphonenumber-js";
import {mapGetters} from "vuex";

export default {
  props: {
    validation: {
      type: Boolean,
      default: true
    },
    limitToCountry: {
      type: String,
      default: undefined,
      required: false
    }
  },
  components: {
    VuePhoneNumberInput,
    CodeInput
  },
  watch: {
    mobilePayload: {
      handler: function(payload) {
        if (payload.countryCode === "CI" && payload.isValid) {
          //this.mobilePayload = {...this.mobilePayload, isValid: false}
        }
      },
      deep: true
    }
  },
  data() {
    return {
      mobile: this.$store.getters.user.Contact.Mobile,
      mobilePayload: null,
      mobileExist: false,
      mobileValidated: false,
      mobileIsValid: false,
      errorMessage: null,
      codeInvalidText: false,
      codeSent: false,
      verificationCode: null,
      progress: 0,
      myInterval: null,
      showResend: false
    };
  },
  computed: {
    ...mapGetters(["user"]),
    currentMobile() {
      if (this.mobileValidated == true) return true;
      if (this.mobilePayload) {
        if (
          this.mobilePayload.nationalNumber == this.$store.getters.userMobile &&
          this.$store.getters.userMobile != null
        ) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    setMobilePayload(event) {
      if(!event.phoneNumber) return false
      const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);
      if(mobileNumber) {
        const payload = {
          ...mobileNumber,
          isValid: mobileNumber ? mobileNumber.isValid() : false,
          countryCallingCode: mobileNumber.countryCallingCode,
          formatInternational: mobileNumber.formatInternational(),
          formatNational: mobileNumber.number,
          formattedNumber: mobileNumber.number,
          nationalNumber: mobileNumber.number,
          uri: mobileNumber.getURI(),
          e164: mobileNumber.ext
        }
        this.mobilePayload = payload;
      }
      this.mobileValidation();
      this.$nextTick(() => {
        this.$emit(
          "mobileChange",
          this.mobilePayload.nationalNumber,
          this.currentMobile,
          this.verificationCode,
          this.mobilePayload.countryCallingCode
        );
      });
    },
    async sendCode() {
      try {
        //this.$bvModal.show("mobileVerification");
        if (!this.mobilePayload.isValid || this.mobile === null) {
          this.errorMessage = this.$t("Validations.mobile.incomplete") + ' #';
        }
        else if (this.mobilePayload.formattedNumber === this.$store.getters.userMobile) {
          console.log(this.mobile)
          this.errorMessage = this.$t("Validations.mobile.same")
        } else {
          await axios.post("user/SetUser", {
            Contact: {
              Mobile: this.mobilePayload.formattedNumber
            }
          });
  
          this.codeSent = true;
          this.showResend = false;
          this.progress = 0;
  
          this.myInterval = setInterval(this.incrementProgress, 1000);
          await this.reSendCode();
        }
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    async reSendCode() {
      try {
        await axios.post("user/MobileResendCode", {
          LoginKey: localStorage.getItem("token"),
          Mobile: this.mobilePayload.formattedNumber
        });

        //this.$bvModal.show("mobileVerification");
        this.codeSent = true;
        this.showResend = false;
        this.progress = 0;
        this.$refs.CodeInput.values = ["", "", "", ""];
        this.myInterval = setInterval(this.incrementProgress, 1000);
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    onComplete(v) {
      this.verificationCode = v;
      if (this.mobileValidate(v)) this.changeNumber();
    },
    async mobileValidate(Code) {
      try {
        let codeIsValid = await axios.post("user/LoginVerify", {
          Code,
          LoginKey: localStorage.getItem("token")
        });
        if (codeIsValid.data) {
          this.$store.dispatch("user", {
            ...this.$store.getters.user,
            Contact: {
              ...this.$store.getters.user.Contact,
              Mobile: this.mobilePayload.formattedNumber
            },
            IsMobileVerified: true
          });
          this.$emit("onValidate");
          return true;
        } else {
          this.codeInvalidText = true;
        }
        return false
      } catch (error) {
        this.errorMessage = error.response.data;
      }
    },
    async mobileValidation() {
      /*
      this.verificationCode = null;
      if (this.mobilePayload) {
        this.mobileIsValid = this.mobilePayload.isValid;
        this.mobileValidated = this.$store.getters.user.IsMobileVerified;
      }
*/
    },
    async changeNumber() {
      await axios.post("user/SetUser", {
          Contact: {
            Mobile: this.mobilePayload.formattedNumber
          }
      });
    },
    incrementProgress() {
      this.progress += 1;
      if (this.progress >= 60) {
        clearInterval(this.myInterval);
        this.showResend = true;
      }
    },
    revalidateMobile() {
      this.mobile = null;
      this.mobilepayload = null;
      this.mobileexist = false;
      this.mobilevalidated = false;
      this.mobileIsValid = true;
      this.errormessage = null;
      this.codeSent = false;
      this.verificationcode = null;
      this.progress = 0;
      this.myinterval = null;
      this.showresend = false;
    }
  },
  beforeMount() {
    this.mobileValidation();
  }
};
</script>
<style lang="scss">
.input-tel__input {
  border-radius: 0 !important;
  border-right: 0 !important;
}

.country-selector__input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  text-align: center;
  border-right: 0 !important;
}

.select-country-container {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.phone-wrapper {
  input {
    height: 48px !important;
  }
  .input-group-append {
    height: 48px !important;
    button {
      border-left: 0 !important;
    }
  }
  .country-selector {
    height: 100%;
  }
  .ReservationWizard .country-selector__country-flag {
    top: 19px !important;
  }
}
</style>
<style lang="scss" scoped>
.phone-wrapper {
  border-radius: 40px;
}
</style>
