<template>
    <div class="d-flex flex-column gap-3 py-2 px-5">
        <CustomerServiceButton
            :text="$t('Contact.CustomerServiceModal.WhatsApp')"
            :link="`https://wa.me/${tab === 0 ? buyerCountryContactDetails[searchCountry].whatsapp : tab === 1 ? sellerCountryContactDetails[searchCountry].whatsapp : ''}`" 
        />
        <CustomerServiceButton
            :text="$t('Contact.CustomerServiceModal.Email')"
            :link="`mailto: ${tab === 0 ? buyerCountryContactDetails[searchCountry].email :  tab === 1 ? sellerCountryContactDetails[searchCountry].email : ''}`"
        />
        <CustomerServiceButton
            :text="$t('Contact.CustomerServiceModal.Phone')"
            :link="`tel: ${tab === 0 ? buyerCountryContactDetails[searchCountry].phone :  tab === 1 ? sellerCountryContactDetails[searchCountry].phone : ''}`"
        />
    </div>
</template>

<script>
import CustomerServiceButton from "./CustomerServiceButton.vue"

export default {
    props: {
        searchCountry: {
            required:true
        },
        tab: {
            type: Number,
            required:true
        }
    },
    components: {
        CustomerServiceButton
    },
    mounted() {
        console.log(this.searchCountry)
    },
    data () {
        return {
            buyerCountryContactDetails: {
                SN: {
                    whatsapp: '+221773648423',
                    email: 'contact@pashmotors.com',
                    phone: '+221773648423'
                },
                CM: {
                    whatsapp: '+237699339234',
                    email: 'contact@pashmotors.com',
                    phone: '+237699339234'
                },
                CI: {
                    whatsapp: '+2250777051848',
                    email: 'contact@pashmotors.com',
                    phone: '+2250777051848'
                },
            },
            sellerCountryContactDetails: {
                CA: {
                    whatsapp: '+514773648423',
                    email: 'contact@pashmotors.com',
                    phone: '+514773648423'
                }
            }
        }
    }
}
</script>

<style>

</style>