<template>
    <div id="sticky-rep" class="d-flex position-fixed" :class="openedModal ? 'sticky-rep-opened' : ''">
      <div class="position-fixed w-100 h-100 bg-dark" 
      style="top:0; left:0;opacity:50%" 
      @click="openedModal = false"
      v-if="openedModal"
      />
        <div id="sticky-rep-container" class="position-relative shadow-lg">
            <v-btn
            color="#FFC225"
            id="rep-button"
            class="black--text p-3"
            :class="openedModal ? 'd-none d-md-flex' : 'd-md-flex'"
            block
            @click="handleOpened(); openedModal = !openedModal"
            >
                <AccountVoice v-if="!openedModal"/>
                <span v-if="!openedModal" class="pl-3">
                    {{ $t("Contact.CustomerServiceModal.Label") }}
                </span>
                <span v-else>
                    {{$t('dashboard.label.close')}}
                </span>
            </v-btn>
            <section class="bg-light rounded-lg d-flex flex-column justify-content-between">
              <div class=''>
                <div class="p-3 p-sm-4 p-lg-5 mx-auto" style="max-width:600px">
                  <div class="mb-5" v-if="showTabs">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a class="nav-link" 
                            :class="tab === 0 ? 'active' : ''"
                            @click="setTab(null, 0)">{{$t('Footer.Footer.Buyers')}}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" 
                        :class="tab === 1 ? 'active' : ''"
                        @click="setTab(null, 1)">{{$t('navBar.label.sellers')}}</a>
                      </li>
                    </ul>
                  </div>
                  <h3 class="text-center text-lg-left text-dark">
                    {{ $t("Contact.CustomerServiceModal.Country") }}
                    </h3>
                    <h6 class="text-center text-lg-left text-muted">
                        {{ $t("Contact.CustomerServiceModal.ConnectDesc") }}
                    </h6>
                  <div>
                      <div v-if="tab === 0" class="mt-5 text-secondary w-100 w-md-50 sales-representative">
                        <div class="homepage-form-body">
                          <div :class="searchCountryInput || searchCountry ? 'has-flag' : ''">
                            <v-select
                              :menu-props="{ bottom: true, offsetY: true }"
                              :label="$t(`Buy.Header.DeliveryCountry`)"
                              :items="activeBuyerCountries"
                              background-color="#fff"
                              v-model="searchCountry"
                              item-value="Code"
                              item-text="Name"
                              outlined
                              light
                              dense
                              return-object
                              ref="countrySelector"
                              class="select-country"
                              @change="handleSelection($event)"
                            >
                              <template v-slot:prepend>
                                <country-flag :country="searchCountryInput" class="flag" v-if="searchCountryInput || searchCountry" />
                                <img src="@/assets/img/globe.png" alt="" v-else />
                              </template>
                            
                              <template #item="{ item }">
                                <country-flag :country="item.Code ? item.Code.toLowerCase() : ''" class="flag" />
                                {{ item.Name }}
                              </template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                    <CustomerServiceButtons v-if="searchCountryInput || searchCountry" :searchCountry="searchCountryInput" :tab="tab"/>
                </div>
              </div>
                <div id="cs-logo-container" class="text-white rounded-bottom">
                  <p class="mx-auto pashmotors-logo my-6">
                    PashMotors
                  </p>
                </div>
               <div class="d-lg-none">
                    <v-btn
                        color="#F3B106"
                        block
                        @click="handleOpened();openedModal = false"
                        >
                        {{$t('dashboard.label.close')}}
                    </v-btn>
                  </div>
                </div>
                </section>
              </div>
            </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import AccountVoice from "vue-material-design-icons/AccountVoice.vue"
import {CustomerServiceButtons} from "@/components/global/CustomerService"
import openModal from "@/components/mixins/openModal.js"

export default {
    data() {
        return {
            searchCountryInput: null,
            showTabs: false,
            tab: null,
            buyRoutes: ['Buy', 'SearchResults', 'BuyerDashboard', 'MyWatchList',
                        'MyReservations', 'MyPurchased', 'MyOrder', 'OverView',
                        'Reservation', 'ReservationCancel', 'ReservationSuccess'],
            sellRoutes: ['Sell', 'WorkwithUs', 'Contact', 'SellerDashboard',
                        'PendingSales', 'ConfirmedSales', 'Archived', 'MyListings',
                        'listingOption', 'ListingVehicle', 'ListingCancel', 'ListingSuccess']
        }
    },
    watch: {
      $route(route){
        this.setTab(route)
      },
      country() {
        this.setSellerCountry()
      }
    },
    components: {
        AccountVoice,
        CustomerServiceButtons
    },
    mixins: [openModal],
    computed: {
      ...mapGetters(['activeBuyerCountries', 'country', 'customerServiceModal']),
      openedModal: {
        get() {
          return this.customerServiceModal;
        },
        set(opened) {
          this.setCustomerServiceModal(opened);
        }
      },
        searchCountry: {
          get() {
            try {
                const destinationCountry = this.$store.state.search.destinationCountry;
                if (this.tab === 0 && destinationCountry) {
                  this.searchCountryInput = destinationCountry.Code;
                  return destinationCountry;
                }
                if (this.$store.state.user.country && this.$store.state.user.country.CanBuy) {
                  this.searchCountryInput = this.$store.state.user.country.Code;
                  return this.$store.getters.country;
                } else {
                  return null;
                }
            }catch (error){
              console.log(error);
            }
          },
          set(country) {
            try {
                this.searchCountryInput = country.Name;
            }catch (error){
              console.log(error);
            }
          },
        },
  },
  methods: {
    ...mapActions(['setCustomerServiceModal']),
    handleSelection(country) {
      this.searchCountryInput = country.Code
    },
    setTab(route, tabNo) {
      if (route) {
        if (this.buyRoutes.includes(route.name)) {
            this.tab = 0
          } else if (this.sellRoutes.includes(route.name)) {
            this.tab = 1
          } else if (this.tab === null) {
            this.tab = 0
          }
      } else this.tab = tabNo
      this.setSellerCountry()
    },
    setSellerCountry() {
      if (this.tab === 1) {
        if (this.country.CanSell) {
          this.showTabs = true
          this.searchCountryInput = this.country.Code;
          return this.country;
        } else {
          this.tab = 0;
          this.showTabs = false;
        }
      } else {
        !this.country.CanSell ? this.showTabs = false : this.showTabs = true;
      }
    },
  },
  created() {
    if (this.country.CanSell) {
      this.showTabs = true;
      this.setTab(this.$route)
    } else this.setTab = 0;
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  color:unset;
  border-top:0!important;
  border-left:0!important;
  border-right:0!important;
  &:hover, &:focus {
    border-color: transparent!important;
  }
}
.active {
  border-bottom: 3px solid $light-blue!important;
  font-weight: bold;
  &:hover, &:focus {
    border-color:  $light-blue!important;
  }
}
#cs-logo-container {
  display:none;
  background-color: $light-blue;
  @media screen and (min-width:992px) {
    display:flex;
  }
}
.rounded-bottom {
  @media screen and (max-width: 991px) {
    border-radius:0!important;
  }
}
    #sticky-rep {
        z-index: 100;
        transition: 0.5s all ease-in-out;
        bottom:0;
        left:100%;
        @media screen and (min-width:992px) {
            right: -600px;
            bottom: 3%;
        }
    }
    .sticky-rep-opened {
        left:unset!important;
        right: 0!important;
    }
    #rep-button {
        position: absolute;
        bottom: 160px;
        min-width: unset!important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transform: rotate(-90deg);
        transform-origin: 0% 100%;
        z-index:98;
        @media screen and (min-width:768px) {
          bottom:80px;
        }
    }
    section {
        position:relative;
        z-index:99;
        width:100vw;
        @media screen and (min-width:992px) {
            width:600px;
        }
    }
    .flag {
  border-radius: 100% !important;
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
}
.f-cm {
  background-position: -6px -1968px !important;
}
.f-sn {
  background-position: -6px -8693px !important;
}
.f-ci {
  background-position: -6px -1845px !important;
}
</style>