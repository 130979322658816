<template>
  <div id="socket">
    <!-- Start Socket -->
    <div class="container socket">
      <div class="row">
        <p class="footer-text-color col text-center socket-item-wrapper">
          <span class="item mb-1 mb-sm-0"
            >&copy; {{ new Date().getFullYear() }} PashMotors
            <span class="divider">|</span>
            {{ $t("Footer.AllRightsReserved") }}</span
          >
          <span class="item">
            <span>
              <span class="divider remove">|</span>
              <router-link class='link-underline footer-text-color' :to="{name: 'MoreLegalInformation', params: {type: 'privacy-policy'}}">
                {{ $t("Footer.Footer.PrivacyPolicy") }}
              </router-link>
            </span>
            <span>
              <span class="divider">|</span>
              <router-link class='link-underline footer-text-color' :to="{name: 'MoreLegalInformation', params: {type: 'terms-of-use'}}">
                {{ $t("Footer.Footer.TermsOfUse") }}
              </router-link>
            </span></span
          >
        </p>
      </div>
    </div>
    <!-- End Socket -->
    <b-modal centered hide-header hide-footer size="md" id="comingSoon">
      <h1 class="m-5 text-center text-half-dark">Coming Soon...</h1>
    </b-modal>
    <TheDeliveryCountryModal />
    <TheDeliveryCountryModal full-control modal-id="selectDeliveryCountryCurrencyFull" />
    <TheNoCountry />
    <template v-if="user && !user.Roles.includes('Seller')">
      <TheBeSellerModal />
    </template>
  </div>
</template>

<style scope lang="scss">
.socket p {
  font-size: small;
  margin-bottom: 0 !important;
}
</style>

<script>
import TheDeliveryCountryModal from "@/components/global/TheDeliveryCountryModal";
import TheBeSellerModal from "@/components/global/TheBeSellerModal";
import {mapGetters} from "vuex";

export default {
  components: {
    TheDeliveryCountryModal,
    TheBeSellerModal,
    TheNoCountry: () => import("@/components/global/TheNotFound.vue"),
  },
  props: {
    section: {
      type: Object,
    },
  },
  computed:{
    ...mapGetters(["user"]),

  },
  data() {
    return {
      menu: null,
      text: null,
    };
  },
};
</script>

<style scoped lang="scss">
.socket p {
  color: #d9d9d9;
}
.socket {
  padding-bottom: 32px;
}
#socket {
  background-color: #1c1c1c;
}
.divider {
  margin: 0 8px;
}
@media screen and (max-width: 575px) {
  .socket-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .socket-item-wrapper .item {
    width: 100%;
  }
  .divider.remove {
    display: none;
  }
  .divider {
    margin: 0 4px;
  }
}
</style>
