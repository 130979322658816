<template>
  <b-row align-h="center" class="p-5">
    <b-spinner
      style="width: 10rem; height: 10rem; margin: 100px" variant="primary"
    />
  </b-row>
</template>
<script>
export default {
  components: {}
};
</script>
