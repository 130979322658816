<template>
  <div class="bg-secondary">
    <b-modal
      id="signin"
      hide-footer
      hide-header
      centered
      no-stacking
      no-close-on-backdrop
      size="lg"
      @hidden="onCloseModal"
      dialog-class="signInModal"
    >
      <template #default="{ hide }">
        <b-row>
          <b-col class="p-left d-none d-lg-block">
            <div class="fixed-background">
              <div class="text-center text-light mt-5">
                <h2>{{ $t("signIn.label.welcome") }}</h2>
              </div>
              <div class="fixed-wrap">
                <div class="fixed">
                  <div class="signIn_cover"></div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="p-right">
            <div>
              <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
            </div>
            <div class="text-center mt-5">
              <h2>{{ $t("signIn.label.title") }}</h2>
            </div>
            <section v-if="steps === 1">
              <div class="col-md-10 m-auto">
                <div class="col-12 text-center">
                  <p class="pt-3 m-0">
                    {{ $t("signIn.label.noAccount") }}
                  </p>
                  <!-- <b-link v-b-modal.register @click="hide()">
                    {{ $t("signIn.label.createAccount") }}
                  </b-link> -->
                  <p>
                    <router-link :to="{ name: 'CreateAccount' }" class="routerLink">
                      <span @click="hide()">
                        {{ $t("signIn.label.createAccount") }}
                      </span>
                    </router-link>
                  </p>
                </div>

                <label class="form-label pl-2 pt-2"
                  >{{ $t("signIn.label.email") }} /
                  {{ $t("signIn.label.phoneNumber") }}</label
                >

                <b-form-input
                  v-show="!switchInput"
                  type="text"
                  id="userEmail"
                  v-model="username"
                  class="input-pills"
                  @input="focusTo(), isEmail"
                  ></b-form-input>
                  <!-- :class="{ 'is-invalid': step && !username }"
                  :state="username ? isEmail || isMobile : null" -->
                <b-input-group v-show="switchInput">
                  <VuePhoneNumberInput
                    id="userMobile"
                    class="col p-0"
                    v-model="username"
                    :default-country-code="
                      $store.getters.country && $store.getters.country.Code
                        ? $store.getters.country.Code
                        : 'CA'
                    "
                    @update="setMobilePayload($event)"
                    :translations="{
                      countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                      countrySelectorError: $t('Contact.countrySelectorError'),
                      phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                      example: $t('Contact.example'),
                    }"
                  />
                  <!-- <b-input-group-append>
                    <div
                      class="right-rounded border border-left-0 px-3 bg-white"
                      :class="isMobile ? 'border-success' : ''"
                    >
                      <b-icon
                        v-if="isMobile"
                        icon="check"
                        variant="success"
                        scale="2"
                        style="margin-top: 13px; margin-right: -5px"
                      ></b-icon>
                    </div>
                  </b-input-group-append> -->
                </b-input-group>

                <div v-show="step && !username" class="invalid-feedback">
                  {{ $t("global.message.fieldEmptyError") }}
                </div>
                <div>
                  <b v-show="availableMobile == false && mobilePayload" class="text-danger m-0 mini-font">
                    <span v-if="isMobile">
                      <b-icon icon="x-circle-fill"/> {{ $t("signIn.label.mobileExist") }}
                      <b-link @click="signInPage = false">{{ $t("signIn.label.createYourAccount") }}</b-link>
                    </span>
                    <span v-else>
                      <b-icon icon="x-circle-fill"/> {{ $t("Validations.mobile.validNumber") }}
                    </span>
                  </b>
                  <b v-show="availableEmail == false" class="text-danger m-0 mini-font">
                    <span v-if="isEmail">
                      <b-icon icon="x-circle-fill"/> {{ $t("signIn.label.emailExist") }}
                      <b-link @click="signInPage = false">{{ $t("signIn.label.createYourAccount") }}</b-link>
                    </span>
                    <span v-else>
                      <b-icon icon="x-circle-fill"/> {{ $t("Validations.email.pleaseEnter") }}
                    </span>
                  </b>
                </div>
              </div>
              <div class="col-md-10 pt-4 pb-5 m-auto">
                <!-- :variant="
                    availableEmail || availableMobile ? 'primary' : 'outline-primary'
                  " -->
                <b-button
                  pill
                  variant="primary"
                  class="w-100"
                  @click="isUserValid()"
                  >
                  <!-- :disabled="username ? !availableEmail && !availableMobile : true" -->
                  {{ $t("signIn.button.continue") }}
                </b-button>
              </div>
            </section>
            <section v-if="steps === 2">
              <div class="col-10 m-auto">
                <b-link @click="steps = 1"
                  ><b> <b-icon icon="arrow-left-square"></b-icon> Back</b></b-link
                >
                <p>{{ $t("signIn.label.sendCodeToUser",{username}) }}</p>

                <b-row>
                  <b-col>
                    <label> Code </label>
                  </b-col>
                  <b-col>
                    <b-link @click="loginSendCode()">Resend the code</b-link>
                  </b-col>
                </b-row>
                <CodeInput
                  :key="componentKey"
                  :loading="false"
                  :fields="4"
                  :autoFocus="true"
                  v-on:complete="onComplete"
                />
                <b-form-checkbox v-model="$store.state.keepLogin" @change="keepLogin()">
                  {{ $t("signIn.label.rememberMe") }}
                </b-form-checkbox>
              </div>
              <div class="col-10 pt-3 m-auto">
                <b-link @click="steps = 3">{{ $t("signIn.label.useMyPasswordInstead") }}</b-link>
              </div>
              <div class="col-10 m-auto">
                <p class="text-danger">{{ errorMessage }}</p>
              </div>
            </section>
            <section v-if="steps === 3">
              <form @submit.prevent="signInSubmit" class="pb-5">
                <div class="col-md-10 m-auto">
                  <p class="py-2">
                    <b-link @click="steps = 1"
                      ><b>{{ username }}</b></b-link
                    >
                  </p>
                  <label class="form-label pl-2 pt-2">{{
                    $t("signIn.label.password")
                  }}</label>
                  <b-input-group>
                    <template #append>
                      <b-input-group-text class="bg-white border-left-0 right-rounded"
                        ><b-icon :icon="eyes" @click="showPassword()"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      :type="passwordField"
                      v-model="password"
                      name="password"
                      class="form-control left-rounded border-right-0"
                    ></b-form-input>
                    <div v-show="submitted && !password" class="invalid-feedback">
                      {{ $t("global.message.fieldEmptyError") }}
                    </div>
                  </b-input-group>
                </div>
                <b-row class="col-11 m-auto text-center pt-2">
                  <b-col class="m-auto p-0">
                    <b-form-checkbox
                      v-model="$store.state.keepLogin"
                      @change="keepLogin()"
                    >
                      {{ $t("signIn.label.rememberMe") }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="m-auto p-0">
                    <b-button
                      class="border-0 p-0 bg-transparent text-primary"
                      v-b-modal.forgotPassword
                    >
                      {{ $t("signIn.label.forgotPassword") }}
                    </b-button>
                  </b-col>
                </b-row>
                <div class="col-10 pt-3 m-auto">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <b-overlay :show="overlay">
                    <b-button
                      pill
                      variant="outline-primary"
                      class="w-100"
                      :disabled="overlay"
                      type="submit"
                    >
                      {{ $t("signIn.button.continue") }}
                    </b-button>
                  </b-overlay>
                </div>
              </form>
            </section>
          </b-col>
          <!-- <b-col v-else>
            <div>
              <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
            </div>
            <div class="text-center my-5 pr-3">
              <h5>{{ $t("signIn.label.createAccountTitle") }}</h5>
            </div>

            <b-form-radio-group
              button-variant="outline-primary"
              size="lg"
              v-model="accountType"
              buttons
              class="pr-3 action-buttons"
              @change="hide(), selectAccountType()"
            >
              <b-form-radio :value="1" class="p-4 mx-2">{{ $t("signIn.label.buyerAccount") }}</b-form-radio>
              <b-form-radio :value="2" class="p-4 mx-2">{{ $t("signIn.label.sellerAccount") }}</b-form-radio>
            </b-form-radio-group>

            <p class="text-center pt-5">
              {{ $t("signIn.label.haveAccount") }}
              <b-link @click="signInPage = true">
                {{ $t("signIn.label.title") }}
              </b-link>
            </p>
          </b-col> -->
        </b-row>
        <!-- <button class="btn btn-facebook" @click="checkLoginState()">
                    <i class="fa fa-facebook mr-1"></i>
                    Login with Facebook
                </button>
        <button class="btn btn-facebook" @click="fbMe()">
                    <i class="fa fa-facebook mr-1"></i>
                    current Facebook
                </button> -->
      </template>
    </b-modal>
    <TheForgotPassword />
  </div>
</template>

<script>
import TheForgotPassword from "@/components/global/TheForgotPassword.vue";
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import CodeInput from "vue-verification-code-input";
import { mapGetters } from "vuex";
import emailPhone from "@/components/mixins/emailPhone";
// import { initFbsdk } from '@/config/fb.js'

export default {
  name: "SignIn",
  components: {
    TheForgotPassword,
    VuePhoneNumberInput,
    CodeInput,
  },
  mixins: [emailPhone],
  computed: {
    ...mapGetters(["signInType"]),
  },
  data() {
    return {
      password: null,
      passwordField: "password",
      eyes: "eye",
      submitted: false,
      overlay: false,
      response: null,
      accountType: null,
      rememberMe: false,
      steps: 1,
      step: false,
      code: null,
      loginKey: null,
    };
  },
  methods: {
    async isUserValid() {
      if (!this.switchInput) await this.checkEmail()
      else await this.checkMobile()
      if (this.availableEmail || this.availableMobile) this.loginSendCode()
    },
    onComplete(v) {
      this.code = v;
      this.loginVerify();
    },
    async signInSubmit() {
      this.overlay = true;
      this.submitted = true;
      const { username, password } = this;
      // const { username, password, accountType } = this;
      // stop here if form is invalid
      if (!username || !password) {
        this.errorMessage = "username or password is missing";
        this.overlay = false;
        return;
      }
      // if (!accountType) {
      //   this.errorMessage = 'please enter your email or phone number';
      //   this.overlay = false;
      //   return;
      // }
      try {
        const response = await axios
          .post(
            "user/LoginVerify",
            {
              LoginKey: this.loginKey,
              Password: password,
            },
            { timeout: 3000 }
          )
          .catch((error) => {
            console.log(error);
            this.errorMessage = error.response.data
              ? error.response.data
              : "Network connection timed out, please try again";
            this.overlay = false;
          });

        this.response = response;
        localStorage.setItem("token", this.loginKey);
        this.$store.dispatch("tokenID", this.loginKey);
        this.overlay = false;
        await this.$store.dispatch("userFromApi", this.loginKey);
        this.$root.$emit("changeLang");
        if (this.$store.getters.user) {
          const routeData = this.$route
          if (routeData.name.includes('OverView')) {
            try {
                await axios.post('Reservation/ReservationValidation', {
                  ListingId:this.$route.params.id
                })
              } catch (err) {
                //error means its reserved
                this.$router.go()
            }
          }
          if (routeData.name.includes('Reservation')) {
            try {
                await axios.post('Reservation/ReservationValidation', {
                  ListingId:this.$route.params.id
                })
              } catch (err) {
                //error means its reserved
                this.$router.push({ name: "OverView", params: { id: routeData.params.id } })
            }
          }
          this.$bvModal.hide("signin");
          this.checkScroll();
        } else {
          this.errorMessage = "something wrong with get user info";
        }
      } catch (error) {
        this.errorMessage = error;
        this.overlay = false;
      }
    },
    checkScroll(){
      //check if reservation login
      if(this.$route.name === 'Reservation'){
        setTimeout(() => {
          const el = document.querySelector('#reservation-userinfo-area');
          if(el) el.scrollIntoView()
        },1000)
      }

    },
    showPassword() {
      if (this.passwordField === "password") {
        (this.passwordField = "text"), (this.eyes = "eye-slash");
      } else if (this.passwordField === "text") {
        (this.passwordField = "password"), (this.eyes = "eye");
      }
    },
    keepLogin() {
      localStorage.setItem("keepLogin", this.$store.getters.keepLogin);
    },
    async loginSendCode() {
      this.componentKey += 1;
      this.errorMessage = null;
      try {
        if (this.isEmail) {
          const response = axios.post(
            "user/Login",
            {
              Email: this.username,
              DeviceName: "edge windows 10",
            },
            { timeout: 3000 }
          );
          this.steps = 2;
          this.step = true;
          this.loginKey = (await response).data;
        } else if (this.isMobile) {
          const response = axios.post(
            "user/Login",
            {
              Mobile: this.mobilePayload.formattedNumber,
              DeviceName: "edge windows 10",
            },
            { timeout: 3000 }
          );
          this.steps = 2;
          this.step = true;
          this.loginKey = (await response).data;
        }
        axios.defaults.headers.common["accesstoken"] = this.loginKey;
      } catch (error) {
        console.log(error);
      }
    },
    async loginVerify() {
      try {
        const response = axios.post(
          "user/LoginVerify",
          {
            Code: this.code,
            LoginKey: this.loginKey,
          },
          { timeout: 3000 }
        );
        console.log(response);
        if ((await response).data) {
          // localStorage.setItem("refreshToken", this.response.data.refreshToken);
          localStorage.setItem("token", this.loginKey);
          this.overlay = false;
          await this.$store.dispatch("userFromApi", this.loginKey);
          this.$root.$emit("changeLang");
          if (this.$store.getters.user) {
            this.$bvModal.hide("signin");
            this.checkScroll();
          } else {
            this.errorMessage = "something wrong with get user info";
          }
        } else {
          this.errorMessage = "something wrong with code, please try again";
        }
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error);
      }
    },
    // selectAccountType() {
    //   this.$store.dispatch("accountType", this.accountType);
    //   this.$router.push({ name: "RegisterPage" });
    // },
    onCloseModal() {
      this.signInPage = true;
      this.$store.dispatch("setSignInModalType", "SIGN_IN");
    },
    // async checkLoginState () {
    //       // login with facebook then authenticate with the API to get a JWT auth token
    //       FB.login(function(response) {
    //         // handle the response
    //         console.log(response)
    //       }, {scope: 'email'})
    //     // await apiAuthenticate(authResponse.accessToken);
    //     },
    //   fbMe() {
    //     FB.api('/me', {fields: ['email', 'first_name', 'last_name']}, function(response) {
    //       console.log(response);
    //     });
    //   }
  },
  mounted() {
    // initFbsdk()
    this.keepLogin();
  }
};
</script>

<style scope lang="scss">


// .modal-backdrop {
//    background-color: $blue-black !important;
//    opacity: 1 !important;
// }

.signIn_cover {
  background-color: $primary-darker;
  opacity: 0.9;
  height: 100%;
}

.fixed-background {
  height: 100%;
}

.mini-font {
  font-size: 0.7rem;
  color: $gray-600;
}

.input-pills {
  border-radius: 50px !important;
}

.left-rounded {
  border-radius: 50px 0px 0px 50px !important;
}

.right-rounded {
  border-radius: 0px 100px 100px 0px !important;
}

.p-left {
  padding-left: 15px !important;
  padding-right: 0 !important;
}

.p-right {
  padding-left: 0 !important;
  padding-right: 15px !important;
}

@media (max-width: 991px) {
  .p-left,
  .p-right {
    padding: 0 1rem !important;
  }
}
.action-buttons {
  label {
    &:hover {
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.signInModal .modal-open,
.signInModal .modal-body {
  padding: 0 !important;
}
.input-tel__input {
  outline: none !important;
}
.modal {
  .close {
    position: absolute;
    right: 25px;
    top: 10px;
  }
}
</style>
