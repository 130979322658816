<template>
  <!-- Start Footer -->
  <footer id="footer" class="footer container-fluid">
    <b-container>
      <div class="row text-light">
        <div class="col-12 text-left has-order">
          <router-link :to="{ name: 'Home' }" class="py-0 pashmotors-logo text-white">
                PashMotors
              </router-link>

          <!--
        <ul class="list-inline mt-3">
          <li class="list-inline-item mr-4">
            <ShareNetwork
              network="twitter"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
            >
              <a class="text-waterBlue"><i class="fa fa-twitter fa-lg"></i></a>
            </ShareNetwork>
          </li>
          <li class="list-inline-item mr-4">
            <ShareNetwork
              network="facebook"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
            >
              <a class="text-seaBlue"><i class="fa fa-facebook fa-lg"></i></a>
            </ShareNetwork>
          </li>
          <li class="list-inline-item mr-4">
            <ShareNetwork
              network="email"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
            >
              <a class="text-danger"><i class="fa fa-google-plus fa-lg"></i></a>
            </ShareNetwork>
          </li>
        </ul>
-->

          <p class="about-pash-wrapper footer-text-color">
            <small class="about-pash">
              <!-- {{ $t("footer.label.contentBrief") }} -->
              {{ $t("Footer.Footer.CommittedToOffering") }}
            </small>
          </p>
        </div>

        <b-col class="order-1 order-lg-2 mb-xl-0 footer-links">
          <b-row>
            <b-col class="col-6 mb-4 col-md mb-lg-0">
              <h6>{{ $t("Footer.Footer.Buyers") }}</h6>
              <div v-for="(item, index) in buyerLinks" :key="'footerBuy' + index">                
                <p>

                    <b-link v-if="item.link === '/' + $t('Contact.Link.ContactRepresentatives')"
                      @click="openModal()"
                    class="link-underline footer-text-color">
                      {{ item.text }}
                    </b-link>
                    <b-link v-else class="link-underline footer-text-color" 
                    :to="item.link" 
                    @click="item.link === item.anchor ? goTo(item.anchor) : ''">
                      {{ item.text }}
                    </b-link>
                  </p>
              </div>
            </b-col>
            <b-col class="col-6 mb-4 col-md mb-lg-0">
              <h6>{{ $t("Footer.Footer.Seelers") }}</h6>
              <div v-for="(item, index) in sellerLinks" :key="'footerSell' + index">                
                <p>
                    <b-link class="link-underline footer-text-color" :to="item.link" @click="item.anchor ? goTo(item.anchor) : ''">
                    {{ item.text }}
                  </b-link>
                  </p>
              </div>
            </b-col>
            <b-col class="col-6 mb-4 col-md mb-lg-0">
              <h6>{{ $t("navBar.label.workWithPASH") }}</h6>
              <p>
                  <b-link class="link-underline footer-text-color" :to="{ name: 'Contact', params: { type: $t('navBar.link.InspectionAgents') } }">
                    {{ $t("navBar.label.inspectionAgents") }}
                  </b-link>
                </p>
                <p>
                  <b-link class="link-underline footer-text-color"
                    :to="{
                      name: 'Contact',
                      params: { type: $t('navBar.link.FreightForwarders') },
                    }"
                  >
                  {{ $t("navBar.label.freightForwarders") }}
                </b-link>
                </p>
              <p>
                  <b-link class="link-underline footer-text-color" :to="{ name: 'Contact', params: { type: $t('navBar.link.CustomsBrokers') } }">
                  {{ $t("navBar.label.customsBrokers") }}
                </b-link>
                </p>
                <p>
                  <b-link class="link-underline footer-text-color"
                    :to="{
                      name: 'Contact',
                      params: { type: $t('navBar.link.SalesRepresentatives') },
                    }"
                  >
                  {{ $t("navBar.label.salesRepresentatives") }}
                </b-link>
                </p>
                <p>
                  <b-link class="link-underline footer-text-color" :to="{ name: 'Contact', params: { type: $t('WorkWithUs.Jobs') } }">
                  {{ $t("navBar.label.jobs") }}
                </b-link>
                </p>
            </b-col>
            <b-col class="col-6 mb-4 col-md mb-lg-0 mx-auto">
              <h6>{{ $t("Footer.Footer.Company") }}</h6>
              <p>
                  <b-link class="link-underline footer-text-color" :to="{ name: 'Contact-Us' }">
                  {{ $t("Footer.Footer.AboutUs") }}
                </b-link>
                </p>
                <!-- <p>
                  <b-link class="link-underline footer-text-color" :to="'/contact/'+$t('WorkWithUs.Jobs')">
                  {{ $t("Footer.Footer.Partners") }}
                </b-link>
                </p> -->
                <p>
                  <b-link class="link-underline footer-text-color" :to="{ name: 'LegalInformation' }">
                  {{ $t("Footer.LegalInformation") }}
                </b-link>
                </p>
                <p>
                  <b-link class="link-underline footer-text-color" :to="{ name: 'HelpCenter' }">
                  {{ $t("Footer.HelpCenter") }}
                </b-link>
                </p>
            </b-col>
            <!-- <b-col class="col-6 mb-4 col-lg offset-lg-0 mb-lg-0">
            <h6>{{ $t("Footer.Footer.Legal") }}</h6>
            <b-link class="link-underline footer-text-color" v-b-modal.comingSoon>
              <p>{{ $t("Footer.Footer.PrivacyPolicy") }}</p>
            </b-link>
            <b-link class="link-underline footer-text-color" v-b-modal.comingSoon>
              <p>{{ $t("Footer.Footer.TermsOfUse") }}</p>
            </b-link>
          </b-col> -->
          </b-row>
        </b-col>
        <!-- <b-modal centered hide-header hide-footer size="md" id="comingSoon">
        <h1 class="m-5 text-center text-half-dark">Coming Soon...</h1>
      </b-modal> -->
      </div></b-container
    >
  </footer>
  <!-- End Footer -->
</template>

<script>
import buyLinks from '../mixins/buyLinks';
import sellLinks from '../mixins/sellLinks';
import {mapActions} from "vuex"
export default {
  data() {
    return {
      sharing: {
        url: "https://pashmotor.com/",
        title: "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description: "Brand new development setup created by Evan You.",
        quote: "The hot reload is so fast it's near instant. - Evan You",
      },
      text: null,
    };
  },
  mixins: [buyLinks, sellLinks],
  methods: {
    ...mapActions(['setCustomerServiceModal']),
    openModal() {
      this.setCustomerServiceModal(true)
    },
    //Retrieves the footer categories from the API, footer is pageID 179
    // async getFooterMenu() {
    //   this.loading = true;
    //   try {
    //     const response = await axios.post("SP/Post/Content_Page_Get", {
    //       Lang: "en",
    //       PageID: 179,
    //       outParentPageID: 1,
    //       outParentSlug: ""
    //     });
    //     this.loading = false;
    //     this.menu = JSON.parse(response.data.result).Table;
    //   } catch (error) {
    //     this.loading = false;
    //     this.errorMessage = error.response.data;
    //   }
    // },
    goTo(anchor) {
      document.getElementById(anchor).scrollIntoView();
    },
  },
  computed: {
    isDashboardPage() {
      if (this.$route.matched[0]) {
        if (this.$route.matched[0].path === "/dashboards") {
          return true;
        } else return false;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  background-color: #1c1c1c;
  padding-bottom: 32px;
  padding-top: 32px;
}
.footer_side_distance {
  margin-left: 30px;
}
.footer-links {
  padding-bottom: 16px;
  @media (min-width: 576px) {
    padding-bottom: 32px;
  }
}
.footer-links a,
.about-pash {
  font-size: 14px;
}
h6 {
  margin-bottom: 24px;
  color: #f8f9fa;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 767px) {
    margin-bottom: 32px;
    font-weight: 500;
  }
}
.has-order {
  order: 3;
  text-align: center !important;
  border-top: 1px solid #d0d0d061;
  padding-top: 32px;
  @media (max-width: 575px) {
    padding: 16px 0 0 0;
  }
}
.about-pash-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 690px;
  margin-top: 24px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.navbar-brand {
  padding-top: 0;
}
p {
  margin-bottom: 8px;
}
.col-6.col-md.col {
  padding-left: 0;
}
</style>
