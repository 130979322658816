<template>
  <a :href="link" class="cs-button border border-primary rounded-pill" target="_blank">
    <div class="d-flex justify-content-between px-5 py-4 shadow-sm rounded-pill">
      <div>
        <b>
          {{ text }}
        </b>
      </div>
      <div>
        <MenuRight/>
      </div>
    </div>
  </a>
</template>

<script>
import MenuRight from 'vue-material-design-icons/MenuRight.vue';

export default {
    props: {
        text: {
            required: true,
            type: String
        },
        link: {
          required:true,
          type: String
        }
    },
    components: {
      MenuRight
    }
}
</script>

<style lang="scss" scoped>
.cs-button {
    background-color: white!important;
    transition: .25s all ease-in-out;
}
.cs-button:hover{
        background-color:$light-blue!important;
        color:white!important;
    }
</style>