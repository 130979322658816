import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./axios";
import "@/assets/sass/custom.scss";
import "@/assets/sass/enzuzo.scss";
import "vue-toastification/dist/index.css";
import Vuelidate from 'vuelidate'

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import vueAwesomeCountdown from "vue-awesome-countdown";

import CountryFlag from "vue-country-flag";

import VueGoodWizard from "vue-good-wizard";

import Multiselect from "vue-multiselect";

import VueResizedImage from "vue-resized-image";

import VueLazyload from "vue-lazyload";

import VueSocialSharing from "vue-social-sharing";

import VCreditCard from "v-credit-card";

import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

import { Plugin as VueStorage } from "vue2-storage";

import Toast from "vue-toastification";

import VueAnalytics from "vue-analytics";
import vuetify from "./plugins/vuetify";

// register globally
Vue.component("multiselect", Multiselect);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(vueAwesomeCountdown, "vac");

Vue.component("country-flag", CountryFlag);

Vue.use(VueGoodWizard);

Vue.use(VueLazyload);

Vue.use(VueResizedImage);

Vue.config.productionTip = false;

Vue.use(VueSocialSharing);

Vue.use(VueStorage);
// You can pass options
Vue.use(VueStorage, {
  prefix: "pash_",
  driver: "local",
  ttl: 60 * 60 * 72 * 1000, // 24 hours
  replacer: (key, value) => value
});

const options = {
  // You can set your default options here
};

Vue.use(Toast, options);
Vue.use(Vuelidate)

Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);

Vue.component("v-credit-card", VCreditCard);

Vue.use(VueAnalytics, {
  id: "G-C3J2EKWBXL",
  router
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
