import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/pages/Home.vue";
import NotFound from "@/views/pages/404";
import store from "../store/index.js";

Vue.use(VueRouter);
// Vue.use(i18n);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: to => {
      return store.state.user.lang == 'en' ? '/buy' :
      store.state.user.lang == 'fr' ? '/acheter' : 
      '/buy';
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.pageContent !== null) {
        next();
      }
    },
  },
  {
    path: "/buy",
    alias: '/acheter',
    name: "Buy",
    beforeEnter: async (to, from, next) => {
      handleLang(to, '/buy', '/acheter')
      next();
    },
    component: () =>
    import(/* webpackChunkName: "buy" */ "@/views/pages/Buy3.vue"),
  },
  {
    path: "/sell",
    alias: "/vendre",
    name: "Sell",
    beforeEnter: (to, from, next) => {
      handleLang(to, '/sell', '/vendre')
      next();
    },
    component: () =>
      import(/* webpackChunkName: "sell" */ "@/views/pages/Sell.vue"),
  },
  {
    path: "/work-with-pashmotors",
    alias: "/travailler-avec-pashmotors",
    name: "WorkwithUs",
    beforeEnter: (to, from, next) => {
      handleLang(to, '/work-with-pashmotors', '/travailler-avec-pashmotors')
      next();
    },
    component: () =>
      import(
        /* webpackChunkName: "work-with-us" */ "@/views/pages/WorkWithUs.vue"
      ),
  },
  {
    path: "/about-PASH",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about-pash" */ "@/views/pages/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact-Us",
    component: () =>
      import(
        /* webpackChunkName: "contact-pash" */ "@/views/pages/ContactUs.vue"
      ),
  },
  {
    path: "/contact/:type",
    name: "Contact",
    props: true,
    // beforeEnter: async (to, from, next) => {
    //   const type = to.params.type.toLowerCase();
    //   if (store.state.user.lang != 'en') {
    //     switch (type) {
    //       case 'inspection-agents':
    //         next({name: "Contact", params: { type: 'inspection-agents' }})
    //       case 'freight-forwarders':
    //         await store.dispatch("setLang", 'en')
    //       case 'customs-brokers':
    //         await store.dispatch("setLang", 'en')
    //       case 'sales-representatives':
    //         await store.dispatch("setLang", 'en')
    //       case 'jobs':
    //         await store.dispatch("setLang", 'en')
    //       }
    //     } else if (store.state.user.lang != 'fr') {
    //       switch (type) {
    //         case 'agents-d-inspection':
    //           next({name: "Contact", params: { type: 'agents-d-inspection' }})
    //         case 'transitaires':
    //           await store.dispatch("setLang", 'fr')
    //         case 'courtiers-en-douane':
    //           await store.dispatch("setLang", 'fr')
    //         case 'représentant-des-ventes':
    //           await store.dispatch("setLang", 'fr')
    //         case 'emplois':
    //           await store.dispatch("setLang", 'fr')
    //         }
    //     }
    //   next();
    // },
    component: () =>
      import(
        /* webpackChunkName: "contact-pash" */ "@/views/pages/Contact.vue"
      ),
  },
  {
    path: "/contact-representatives",
    alias: "/contact-représentants",
    name: "ContactReps",
    props: true,
    beforeEnter: (to, from, next) => {
      handleLang(to, '/contact-representatives', '/contact-représentants')
      next();
    },
    component: () =>
      import(
        /* webpackChunkName: "contact-reps" */ "@/views/pages/ContactReps.vue"
      ),
  },
  {
    path: "/search/:country?/:currency?",
    alias: "/recherche/:country?/:currency?",
    name: "SearchResults",
    beforeEnter: (to, from, next) => {
      handleLang(to, '/search', '/recherche')
      next();
    },
    component: () =>
      import(/* webpackChunkName: "SearchResults" */ "@/views/pages/Search.vue"),
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: () =>
      import(
        /* webpackChunkName: "dashboards" */ "@/views/pages/Dashboards.vue"
      ),
    children: [
      {
        path: "sellerdashboard",
        name: "SellerDashboard",
        component: () =>
          import("@/components/dashboard/seller/TheDashboardBody.vue"),
      },
      {
        path: "buyerdashboard",
        name: "BuyerDashboard",
        component: () =>
          import("@/components/dashboard/buyer/TheDashboardBody.vue"),
      },
      {
        path: "notifications",
        name: "Notifications",
        component: () =>
          import("@/components/dashboard/myNotifications/TheNotifications.vue"),
      },
      {
        path: "profile",
        name: "AccountSetting",
        component: () =>
          import("@/components/dashboard/myAccount/TheAccountSetting.vue"),
        children: [
          {
            path: "myprofile",
            name: "PrimaryContact",
            component: () =>
              import("@/components/dashboard/myAccount/ThePrimaryContact.vue"),
          },
          {
            path: "myprofilecontactedit",
            name: "PrimaryContactEdit",
            component: () =>
              import(
                "@/components/dashboard/myAccount/ThePrimaryContactEdit.vue"
              ),
          },
          {
            path: "myprofilelocationedit",
            name: "PrimaryLocationEdit",
            component: () =>
              import(
                "@/components/dashboard/myAccount/ThePrimaryLocationEdit.vue"
              ),
          },
          {
            path: "userType",
            name: "UserType",
            component: () =>
              import("@/components/dashboard/myAccount/TheUserType.vue"),
          },
          {
            path: "parameters",
            name: "Parameters",
            component: () =>
              import("@/components/dashboard/myAccount/TheParameters.vue"),
          },
          {
            path: "security",
            name: "Security",
            component: () =>
              import("@/components/dashboard/myAccount/TheSecurity.vue"),
          },
          {
            path: "securityedit",
            name: "SecurityEdit",
            component: () =>
              import("@/components/dashboard/myAccount/TheSecurityEdit.vue"),
          },
        ],
      },
      {
        path: "payment",
        name: "Payment",
        component: () =>
          import("@/components/dashboard/myPayments/ThePaymentSetting.vue"),
        children: [
          {
            path: "gettingpaid",
            name: "GettingPaid",
            component: () =>
              import("@/components/dashboard/myAccount/TheGettingPaid.vue"),
          },
          {
            path: "gettingpaidedit",
            name: "GettingPaidEdit",
            component: () =>
              import("@/components/dashboard/myAccount/TheGettingPaidEdit.vue"),
          },
        ],
      },
      //seller menu
      {
        path: "pendingsales",
        name: "PendingSales",
        component: () =>
          import("@/components/dashboard/seller/ThePendingSales.vue"),
      },
      {
        path: "confirmedsales",
        name: "ConfirmedSales",
        component: () =>
          import("@/components/dashboard/seller/TheConfirmedSales.vue"),
      },
      {
        path: "archived",
        name: "Archived",
        component: () =>
          import("@/components/dashboard/seller/TheArchived.vue"),
      },
      {
        path: "mylistings",
        name: "MyListings",
        component: () =>
          import("@/components/dashboard/seller/TheMyListings.vue"),
      },

      //buyer menu
      {
        path: "mywatchlist",
        name: "MyWatchList",
        component: () =>
          import("@/components/dashboard/buyer/TheMyWatchList.vue"),
      },
      {
        path: "myreservations",
        name: "MyReservations",
        component: () =>
          import("@/components/dashboard/buyer/TheMyReservations.vue"),
      },
      {
        path: "my-purchased",
        name: "MyPurchased",
        component: () =>
          import("@/components/dashboard/buyer/TheMyPurchased.vue"),
      },
      {
        path: "myorder",
        name: "MyOrder",
        component: () => import("@/components/dashboard/buyer/TheMyOrder.vue"),
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/pages/Profile.vue"),
  },
  {
    path: "/overview/:id",
    alias: "/sommaire/:id",
    name: "OverView",
    beforeEnter: (to, from, next) => {
      handleLang(to, '/overview', '/sommaire')
      next();
    },
    component: () =>
      import(
        /* webpackChunkName: "overview" */ "@/views/pages/OverView.vue"
      ),
  },
  //work with PASH pages
  {
    path: "/registration",
    name: "RegisterPage",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/pages/RegisterPage.vue"
      ),
  },
  {
    path: "/create-an-account",
    name: "CreateAccount",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/pages/CreateAccount.vue"
      ),
  },
  {
    path: "/create-your-account/:type",
    name: "CreateAccountType",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/pages/RegisterPage.vue"
      ),
  },
  {
    path: "/ListingOption",
    name: "listingOption",
    component: () =>
      import(
        /* webpackChunkName: "ListingOption" */ "@/views/pages/ListingOption.vue"
      ),
  },
  {
    path: "/listingVehicle",
    name: "ListingVehicle",

    component: () =>
      import(/* webpackChunkName: "listing" */ "@/views/pages/Listing.vue"),
  },
  {
    path: "/reservation/:id",
    name: "Reservation",
    component: () =>
      import(
        /* webpackChunkName: "reservation" */ "@/views/pages/Reservation.vue"
      ),
  },
  {
    path: "/reservationCancel",
    name: "ReservationCancel",
    component: () =>
      import(
        /* webpackChunkName: "reservation Cancel" */ "@/views/pages/ReservationCancel.vue"
      ),
  },
  {
    path: "/reservationSuccess/:id?",
    name: "ReservationSuccess",
    component: () =>
      import(
        /* webpackChunkName: "reservation Success" */ "@/views/pages/ReservationSuccess.vue"
      ),
  },
  {
    path: "/listingCancel",
    name: "ListingCancel",
    component: () =>
      import(
        /* webpackChunkName: "listing Cancel" */ "@/views/pages/ListingCancel.vue"
      ),
  },
  {
    path: "/listingSuccess/:id?",
    name: "ListingSuccess",
    component: () =>
      import(
        /* webpackChunkName: "listing Success" */ "@/views/pages/ListingSuccess.vue"
      ),
  },
  {
    path: "/validate/:id",
    name: "EmailValidation",
    component: () =>
      import(
        /* webpackChunkName: "email validation" */ "@/views/pages/EmailValidation.vue"
      ),
  },
  {
    path: "/resetpassword/:id",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "Reset Password" */ "@/views/pages/ResetPassword.vue"
      ),
  },
  {
    path: "/legal",
    name:"LegalInformation",
    component: () =>
    import(
      "@/views/pages/LegalInformation.vue"
    ),
  },
  {
    path: "/legal/:type",
    name:"MoreLegalInformation",
    props: true,
    component: () =>
    import(
      "@/views/pages/detailedInfo/LegalInformation.vue"
    ),
  },
  {
    path: "/help-center",
    name:"HelpCenter",
    component: () =>
    import(
      "@/views/pages/HelpCenter.vue"
    ),
  },
  {
    path: "/help-center/:subsection",
    name:"HelpCenterSubsection",
    props: true,
    component: () =>
    import(
      "@/views/pages/HelpCenterSubsection.vue"
    ),
  },
  {
    path: "/help-center/:subsection/:type",
    name:"HelpInformation",
    props: true,
    component: () =>
    import(
      "@/views/pages/detailedInfo/HelpInformation.vue"
    ),
  },
  { path: "/404", component: NotFound },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {

    if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const handleLang = async (to, engLink, frLink) => {
  if (to.path.toLowerCase().includes(frLink.toLowerCase()) && store.state.user.lang != 'fr') await store.dispatch("setLang", 'fr');
  else if (to.path.toLowerCase().includes(engLink.toLowerCase()) && store.state.user.lang != 'en') await store.dispatch("setLang", 'en')
}

export default router;
