<template>
  <div class="text-center py-4">
    <lottie :options="defaultOptions" :height="400" :width="400" />
    <h3 class="mb-4">Page Not Found!</h3>
    <div>
      <b-button class="mr-4" size="large" @click="$router.back()"
        >Back to previous page</b-button
      >
      <b-button
        variant="primary"
        size="large"
        @click="$router.push({ path: '/' })"
        >Return home page</b-button
      >
    </div>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import animationData from "@/assets/lottie/404.json";
export default {
  name: "404",
  components: {
    lottie: Lottie
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1
    };
  }
};
</script>

<style scoped></style>
